import { api } from "@/services";

// ONE TRUE SOURCE
const state = {
  sidebarBackground: "orange",
  loadingStatus: "notLoading",
  menu: [],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_LOADING_STATUS(state, status) {
    state.loadingStatus = status;
  },
  SET_MENU(state, menu) {
    state.menu = menu;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async fetchMenu(context, param) {
    context.commit("SET_LOADING_STATUS", "loading");
    let query = param ? "?insideApp=" + param : "";
    await api
      .get("navigation-groups" + query)
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_MENU", response.data);
      })
      .catch(() => {
        // TODO: ADICIONAR TRATATIVA PARA RETORNO COM ERRO NA API DO MENU
      });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
