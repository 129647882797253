import { createApp } from "vue";

// HELPERS
import Toast from "vue-toastification";

// LAYOUTS
import App from "./App.vue";
import ArgonDashboard from "./plugins/argon-dashboard";

// PLUGINS
import store from "./store";
import router from "./router";
import Maska from "maska";

// STYLES
import "vue-toastification/dist/index.css";
import "element-plus/lib/theme-chalk/index.css";

// AUTH INTERCEPTOR
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/loggedIn"]) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["auth/loggedIn"]) {
      next({
        name: "Modulos",
      });
    } else {
      next();
    }
  }
});

// GLOBAL TOAST OPTIONS HERE
const options = {};

createApp(App)
  .use(Toast, options)
  .use(Maska)
  .use(ArgonDashboard)
  .use(router)
  .use(store)
  .mount("#app");
