// ONE TRUE SOURCE
const state = {
  modalGroups: false,
  modalAddMember: false,
  modalAddOffice: false,
  modalLicenseMember: false,
  modalAddLicenseMember: false,
  modalSecurityUser: false,
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_GROUPS(state, payload) {
    state.modalGroups = payload;
  },
  SET_ADD_MEMBER(state, payload) {
    state.modalAddMember = payload;
  },
  SET_ADD_OFFICE(state, payload) {
    state.modalAddOffice = payload;
  },
  SET_LICENSE_MEMBER(state, payload) {
    state.modalLicenseMember = payload;
  },
  SET_SECURITY_USER(state, payload) {
    state.modalSecurityUser = payload;
  },
  SET_ADD_LICENSE_MEMBER(state, payload) {
    state.modalAddLicenseMember = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  toggleModal(context, params) {
    switch (params) {
      case "groups":
        context.commit("SET_GROUPS", !context.state.modalGroups);
        break;
      case "addMember":
        context.dispatch(
          "groupMembers/fetchLicensesAvailables",
          {},
          { root: true }
        );
        context.commit("SET_ADD_MEMBER", !context.state.modalAddMember);
        break;
      case "addOffice":
        context.commit("SET_ADD_OFFICE", !context.state.modalAddOffice);
        break;
      case "licenseMember":
        context.commit("SET_LICENSE_MEMBER", !context.state.modalLicenseMember);
        break;
      case "securityUser":
        context.commit("SET_SECURITY_USER", !context.state.modalSecurityUser);
        break;
      case "addLicenseMember":
        context.commit(
          "SET_ADD_LICENSE_MEMBER",
          !context.state.modalAddLicenseMember
        );
        break;
      default:
        break;
    }
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
