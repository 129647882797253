import { api } from "@/services";
import { mask } from "maska";
import router from "../../router";

const axios = require("axios");

// ONE TRUE SOURCE
const state = {
  // GROUPS
  loadingStatus: "notLoading",
  groupsAvailable: [
    {
      ref: "**",
      name: "**",
      corp_id: "**",
    },
  ],
  selectedGroup: {
    ref: "**",
    name: "**",
    corp_id: "**",
  },
  // PROFILE DATA
  user: {
    fname: "*****",
    lname: "*****",
    image: "",
    ident_number: "******",
    user_address: {
      street: "*****",
      city: "****",
      country: "*****",
      zipcode: "********",
    },
    user: {
      username: "******",
      change_password: false,
    },
    group: {
      name: "*****",
    },
    module_role: {
      name: "*****",
    },
  },
  newPassword: "",
  termsUse: "",
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  // GROUPS
  SET_TERMS_USE(payload) {
    state.termsUse = payload;
  },
  SET_NEW_PASSWORD(state, payload) {
    state.newPassword = payload;
  },
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_GROUPS_AVAILABLE(state, payload) {
    state.groupsAvailable = payload;
  },
  SET_SELECT_GROUP(state, payload) {
    state.selectedGroup = payload;
  },
  // PROFILE DATA
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_ZIPCODE(state, payload) {
    state.user.user_address.zipcode = payload;
  },
  SET_USER_STREET(state, payload) {
    state.user.user_address.street = payload;
  },
  SET_USER_NUMBER(state, payload) {
    state.user.user_address.number = payload;
  },
  SET_USER_DISTRICT(state, payload) {
    state.user.user_address.district = payload;
  },
  SET_USER_CITY(state, payload) {
    state.user.user_address.city = payload;
  },
  SET_USER_STATE(state, payload) {
    state.user.user_address.state = payload;
  },
  SET_USER_ADDRESS(state, payload) {
    state.user.user_address = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async updateTermsUse(context, payload) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .put(`users-infos/${payload.userId}`, {
        terms_use: payload.terms,
      })
      .then((response) => {
        context.dispatch("fetchUser");
        context.commit("SET_TERMS_USE", response.data.terms_use);

        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Termos aceitos com sucesso!",
          },
          { root: true }
        );
      })
      .catch((error) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: error.response.data.message,
          },
          { root: true }
        );
      });
  },
  // PROFILE DATA
  async fetchUser(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("users-infos/module")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_USER", response.data);
        if (getters.get__changePassword(context.state)) {
          router.push({ name: "change" });
        } else {
          if (getters.getAddress(state) == null) {
            const address = {
              street: "",
              city: "",
              country: "",
              zipcode: "",
              state: "",
              number: "",
            };
            context.commit("SET_USER_ADDRESS", address);
          }
        }
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Por favor faça autenticação novamente.",
          },
          { root: true }
        );
        context.dispatch("auth/destroyToken", {}, { root: true });
        router.push({ name: "login" });
      });
  },
  // GROUPS
  async fetchGroups(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    context.dispatch("modals/toggleModal", "groups", { root: true });
    await api
      .get("groups/availables")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_GROUPS_AVAILABLE", response.data);
        context.dispatch("selectGroup", context.state.user.group.id);
      })
      .catch(() => {
        context.dispatch("modals/toggleModal", "groups", { root: true });
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar grupos.",
          },
          { root: true }
        );
      });
  },
  async changeGroup(context) {
    await api
      .put("groups/active/" + context.state.selectedGroup.ref.split("-")[1])
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch("modals/toggleModal", "groups", { root: true });
        context.dispatch("fetchUser");
        context.dispatch("group/fetchGroup", {}, { root: true });
        context.dispatch("modules/fetchModules", {}, { root: true });
        context.dispatch("groupMembers/fetchMembers", {}, { root: true });
        context.dispatch("indicaBen/fetchReports", {}, { root: true });
        context.dispatch(
          "groupMembers/fetchLicensesAvailables",
          {},
          { root: true }
        );

        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: response.data.message,
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch("modals/toggleModal", "groups", { root: true });
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, algo errado com o grupo escolhido.",
          },
          { root: true }
        );
      });
  },
  async updatePassword(context, params) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .post("users-infos/security", {
        currentPassword: params.currentPassword,
        newPassword: context.getters.get__newPassword,
        confirmationPassword: params.newConfirmation,
      })
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        console.log(context.getters.get__changePassword);
        if (context.getters.get__changePassword) {
          router.push({ name: "Modulos" });
        } else {
          context.dispatch("modals/toggleModal", "securityUser", {
            root: true,
          });
        }

        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: response.data.message,
          },
          { root: true }
        );
      })
      .catch((error) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: error.response.data.message,
          },
          { root: true }
        );
      });
  },
  selectGroup(context, params) {
    let selectedGroup = context.state.groupsAvailable.filter(
      (group) => group.id == params
    );
    let payload = {
      ref: "group-" + selectedGroup[0].id,
      initial: false,
      name: selectedGroup[0].name,
      corp_id: selectedGroup[0].corp_id,
    };
    if (context.getters.get__groupFullName == payload.name.toUpperCase()) {
      payload.initial = true;
    }
    context.commit("SET_SELECT_GROUP", payload);
  },
  updateZipcode(context, event) {
    let zipcode = event.target.value.trim();
    context.commit("SET_USER_ZIPCODE", zipcode);
    if (
      zipcode.replace("-", "").length == 8 &&
      !context.state.user.user_address.street
    ) {
      context.dispatch("fetchZipcode", zipcode);
    }
  },
  updateStreet(context, event) {
    context.commit("SET_USER_STREET", event.target.value.trim());
  },
  updateNumber(context, event) {
    context.commit("SET_USER_NUMBER", event.target.value.trim());
  },
  updateDistrict(context, event) {
    context.commit("SET_USER_DISTRICT", event.target.value.trim());
  },
  updateCity(context, event) {
    context.commit("SET_USER_CITY", event.target.value.trim());
  },
  updateState(context, event) {
    context.commit("SET_USER_STATE", event.target.value.trim());
  },
  updateNewPassword(context, event) {
    context.commit("SET_NEW_PASSWORD", event.target.value);
  },
  async updateAddress(context) {
    await api
      .post("user-adresses", context.getters.getAddress)
      .then(() => {
        context.dispatch("fetchUser");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Informações salvas com sucesso!",
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, ocorreu um erro.",
          },
          { root: true }
        );
      });
  },
  async fetchZipcode(context, zipcode) {
    await axios
      .get(`https://brasilapi.com.br/api/cep/v2/${zipcode}`)
      .then((response) => {
        const address = {
          street: response.data.street,
          city: response.data.city,
          country_desc: "Brasil",
          country_code: "1058",
          zipcode: zipcode,
          state: response.data.state,
          number: context.getters.getAddress.number,
          district: response.data.neighborhood,
        };
        context.commit("SET_USER_ADDRESS", address);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar este CEP.",
          },
          { root: true }
        );
      });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {
  get__groupFullName(state) {
    return state.user.group ? state.user.group.name.toUpperCase() : "*****";
  },
  get__newPasswdScore(state) {
    let password = state.newPassword;
    let score = 0;
    let letters = new Object();
    for (let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }
    let variations = {
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += variations[check] == true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return parseInt(score);
  },
  get__newPassword(state) {
    return state.newPassword;
  },
  get__changePassword(state) {
    return state.user.user.change_password;
  },
  getGroupAcronym(state) {
    return (state.user.group.name[0] + state.user.group.name[1]).toUpperCase();
  },
  getSelectCorpId(state) {
    return mask(state.selectedGroup.corp_id, "##.###.###/####-##");
  },
  getUserAcronym(state) {
    return (state.user.fname[0] + state.user.lname[0]).toUpperCase();
  },
  getUserFullname(state) {
    return state.user.fname + " " + state.user.lname;
  },
  getUserAddressLine(state) {
    return (
      state.user.user_address.city +
      " - " +
      state.user.user_address.state +
      ", " +
      state.user.user_address.country_desc
    );
  },
  getAddress(state) {
    return state.user.user_address;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
