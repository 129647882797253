import { api } from "@/services";
import ExcelJS from "exceljs/dist/exceljs.min.js";
import fs from "file-saver";

// ONE TRUE SOURCE
const state = {
  loadingStatus: "notLoading",
  member: "",
  members: [
    {
      img: "img/icons/loading.gif",
      name: "******",
      acron: "**",
      email: "******",
      status: "****",
      statusType: "success",
      licenses: [
        {
          module: "********",
          icon: "fa fa-question",
          role: "********",
        },
      ],
    },
  ],
  filters: {
    search: "",
    role: [],
  },
  licensesModal: {
    user: "****",
    module: "*****",
    icon: "*****",
    role: "*****",
    role_desc: "****",
  },
  addLicensesModal: {
    user: {
      id: 0,
      name: "***",
    },
    module: 0,
    role: 0,
  },
  officeModal: {
    user: {
      office: "",
    },
  },
  addMemberForm: {
    emails: "",
    emails_messages: [],
    licenses: [],
    razao_social: "",
    nome_fantasia: "",
    cnae: "",
    tipo_fornecedor: "",
    tipo_tributacao: "",
    servico_prestado: "",
    codigo_servico: "",
    inscricao_estadual: "",
    inscricao_municipal: "",
    telefone1: "",
    telefone2: "",
    email1: "",
    email2: "",
    nome_contato1: "",
    nome_contato2: "",
    endereco: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "",
    cep: "",
    nome_banco: "",
    agencia: "",
    titularidade: "",
    numero_banco: "",
    conta_corrente: "",
  },
  addMembersInvite: {
    emails: [],
    module: "",
    role: "",
  },
  indexLicenseSelected: 0,
  licensesAvailable: [
    {
      module: "*",
      available: 1,
      module_id: "01",
      icon: "fa fa-user",
      roles: [
        {
          name: "*",
          id: 0,
        },
      ],
    },
  ],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_INDEX_LICENSE_SELECTED(state, payload) {
    state.indexLicenseSelected = payload;
  },
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_MEMBERS(state, payload) {
    state.members = payload;
  },
  SET_MEMBER(state, payload) {
    state.member = payload;
  },
  SET_USER_OFFICE_MODAL(state, payload) {
    state.officeModal.user = payload;
  },
  SET_LICENSE_MODAL(state, payload) {
    state.licensesModal = payload;
  },
  SET_ADD_LICENSE_MODAL(state, payload) {
    state.addLicensesModal.user = payload;
  },
  SET_ADD_LICENSE_MODAL_MODULE(state, payload) {
    state.addLicensesModal.module = payload;
  },
  SET_ADD_LICENSE_MODAL_ROLE(state, payload) {
    state.addLicensesModal.role = payload;
  },
  SET_LICENSES_AVAILABLE(state, payload) {
    state.licensesAvailable = payload;
  },
  // FORM INVITE
  SET_MEMBER_EMAIL_INVITE(state, payload) {
    state.addMembersInvite.emails.push(payload);
  },
  SET_MEMBER_MODULE_INVITE(state, payload) {
    state.addMembersInvite.module = payload;
  },
  SET_MEMBER_ROLE_INVITE(state, payload) {
    state.addMembersInvite.role = payload;
  },
  DESTROY_MEMBER_EMAIL_INVITE(state, payload) {
    state.addMembersInvite.emails = payload;
  },
  //
  SET_MEMBER_EMAIL_FORM(state, payload) {
    state.addMemberForm.emails = payload;
  },
  SET_MEMBER_EMAIL_MESSAGES_FORM(state, payload) {
    state.addMemberForm.emails_messages.push(payload);
  },
  DESTROY_MEMBER_EMAIL_MESSAGES_FORM(state) {
    state.addMemberForm.emails_messages = [];
  },
  DESTROY_MEMBER_EMAILS(state) {
    state.addMembersInvite.emails = [];
  },
  SET_FILTER_SEARCH(state, payload) {
    state.filters.search = payload;
  },
  SET_FILTER_ROLE(state, payload) {
    state.filters.role = payload;
  },
  SET_OFFICE_CORP_ID(state, payload) {
    state.officeModal.user.office.corp_id = payload;
  },
  SET_OFFICE_CORP_NAME(state, payload) {
    state.officeModal.user.office.corp_name = payload;
  },
  SET_OFFICE_REF_NAME(state, payload) {
    state.officeModal.user.office.ref_name = payload;
  },
  SET_OFFICE_DEFAULT(state) {
    state.officeModal.user.office = {
      corp_id: "",
      corp_name: "",
      ref_name: "",
    };
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async sendRmvMemberGroup(context, params) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .post("groups/rmv-member", {
        group_id: params.group,
        member_id: params.member,
      })
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Usuário removido do grupo com sucesso.",
          },
          { root: true }
        );
        context.dispatch("fetchMembers");
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não foi possivel remover o usuário do grupo.",
          },
          { root: true }
        );
      });
  },
  updateSelectedModule(context, index) {
    context.commit("SET_INDEX_LICENSE_SELECTED", index);
  },
  updateOfficeModalCORP_ID(context, event) {
    // CREATE DEFAULT OFFICE IF EMPTY
    !context.state.officeModal.user.office
      ? context.commit("SET_OFFICE_DEFAULT")
      : "";
    context.commit("SET_OFFICE_CORP_ID", event.target.value.trim());
  },
  updateOfficeModalCORP_NAME(context, event) {
    // CREATE DEFAULT OFFICE IF EMPTY
    !context.state.officeModal.user.office
      ? context.commit("SET_OFFICE_DEFAULT")
      : "";
    context.commit("SET_OFFICE_CORP_NAME", event.target.value.trim());
    context.commit("SET_OFFICE_REF_NAME", event.target.value.trim());
  },
  updateOfficeModalREF_NAME(context, event) {
    // CREATE DEFAULT OFFICE IF EMPTY
    !context.state.officeModal.user.office
      ? context.commit("SET_OFFICE_DEFAULT")
      : "";
    context.commit("SET_OFFICE_REF_NAME", event.target.value.trim());
  },
  updateSearch(context, event) {
    context.commit("SET_FILTER_SEARCH", event);
  },
  updateFilterRole(context, event) {
    context.commit("SET_FILTER_ROLE", event);
  },
  addEmail(context, params) {
    if (params.length >= 3) {
      context.commit("DESTROY_MEMBER_EMAIL_MESSAGES_FORM");
      let mails = params.replace(/ /g, ";").toLowerCase().split(";");
      let notValid = [];

      mails.forEach((mail) => {
        let isMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail); // VERIFICA SE O EMAIL É VALIDO
        let existMail = context.state.addMembersInvite.emails.includes(mail); // VERIFICA SE O EMAIL JA FOI ENVIADO
        if (isMail && !existMail) {
          context.commit("SET_MEMBER_EMAIL_INVITE", mail);
        } else {
          existMail
            ? context.commit(
                "SET_MEMBER_EMAIL_MESSAGES_FORM",
                mail + " duplicado."
              )
            : (context.commit(
                "SET_MEMBER_EMAIL_MESSAGES_FORM",
                mail + " não é email válido."
              ),
              notValid.push(mail));
        }
      });
      context.commit("SET_MEMBER_EMAIL_FORM", notValid.join("; "));
    }
  },
  rmvEmail(context, params) {
    let emails = context.state.addMembersInvite.emails;
    context.commit("SET_MEMBER_EMAIL_FORM", emails[params]);
    emails.splice(params, 1);
    context.commit("DESTROY_MEMBER_EMAIL_INVITE", emails);
  },
  async fetchMembers(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("groups/group/members")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_MEMBERS", response.data);
      })
      .catch(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar membros.",
          },
          { root: true }
        );
      });
  },
  async fetchMember(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("groups/group/member")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_MEMBER", response.data);
      })
      .catch(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos recuperar seus dados.",
          },
          { root: true }
        );
      });
  },
  async fetchLicensesAvailables(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("group-licenses/availables")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_LICENSES_AVAILABLE", response.data);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, não conseguimos encontrar licenças disponiveis.",
          },
          { root: true }
        );
      });
  },
  async sendMemberInvite(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .post("group-invites", {
        emails: context.state.addMembersInvite.emails.join(";"),
        form: context.state.addMemberForm,
        payload: context.getters.getAddMemberPayload,
      })
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("DESTROY_MEMBER_EMAILS");
        context.dispatch("modals/toggleModal", "addMember", { root: true });
        context.dispatch("fetchMembers");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Convites enviados para processamento com sucesso. ",
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, Não a licensas disponiveis para todos os convites.",
          },
          { root: true }
        );
      });
  },
  async sendAddLicense(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    let data = {
      member: context.state.addLicensesModal.user.id,
      module:
        context.getters.getMemberLicensesAvailable[
          context.state.addLicensesModal.module
        ].module_id,
      role:
        context.getters.getMemberLicensesAvailable[
          context.state.addLicensesModal.module
        ].roles[context.state.addLicensesModal.role].id,
    };
    await api
      .post("group-licenses/to-member", data)
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Licença atribuida para o membro com sucesso. ",
          },
          { root: true }
        );
        context.dispatch("fetchMembers");
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, ocorreu um erro ao atribuir a licença.",
          },
          { root: true }
        );
      });
    context.dispatch("modals/toggleModal", "addLicenseMember", {
      root: true,
    });
  },
  async sendRmvLicense(context, params) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .post("group-licenses/rmv-member", {
        member: params.user,
        module: params.module,
      })
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Licença removida com sucesso.",
          },
          { root: true }
        );
        context.dispatch("fetchMembers");
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não foi possivel remover a licença.",
          },
          { root: true }
        );
      });
    context.dispatch("modals/toggleModal", "licenseMember", {
      root: true,
    });
  },
  async sendUpdateOffice(context, payload) {
    context.commit("SET_LOADING_STATUS", "loading");
    payload.user_id = context.state.officeModal.user.id;
    await api
      .post("user-offices/group-set-office", payload)
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Grupo atualizado com sucesso.",
          },
          { root: true }
        );
        context.dispatch("fetchMembers");
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, não foi possivel atulizar o escritorio deste usuário.",
          },
          { root: true }
        );
      });
    context.dispatch("modals/toggleModal", "addOffice", {
      root: true,
    });
    context.dispatch("fetchMembers");
  },
  loadLicenseModal(context, params) {
    let modalContent = {
      user: context.getters.getMembers[params.mbIndex].name,
      user_id: context.getters.getMembers[params.mbIndex].id,
      module:
        context.getters.getMembers[params.mbIndex].licenses[params.lcIndex]
          .module,
      module_id:
        context.getters.getMembers[params.mbIndex].licenses[params.lcIndex]
          .module_id,
      icon:
        context.getters.getMembers[params.mbIndex].licenses[params.lcIndex]
          .icon,
      role:
        context.getters.getMembers[params.mbIndex].licenses[params.lcIndex]
          .role,
      role_desc:
        context.getters.getMembers[params.mbIndex].licenses[params.lcIndex]
          .role_desc,
    };
    context.commit("SET_LICENSE_MODAL", modalContent);
    context.dispatch("modals/toggleModal", "licenseMember", { root: true });
  },
  loadAddLicenseModal(context, params) {
    context.dispatch("fetchLicensesAvailables");
    let user = context.getters.getMembers[params];
    context.commit("SET_ADD_LICENSE_MODAL", user);
    context.commit("SET_ADD_LICENSE_MODAL_MODULE", 0);
    context.commit("SET_ADD_LICENSE_MODAL_ROLE", 0);
    context.dispatch("modals/toggleModal", "addLicenseMember", { root: true });
  },
  async loadAddOfficeModal(context, params) {
    let user = context.getters.getMembers[params];
    context.commit("SET_USER_OFFICE_MODAL", user);
    await context.dispatch("modals/toggleModal", "addOffice", { root: true });
  },
  async closeAddOfficeModal(context) {
    await context.dispatch("modals/toggleModal", "addOffice", { root: true });
  },
  useLicenseAvailable(context, params) {
    context.commit("SET_MEMBER_MODULE_INVITE", params.module);
    context.commit("SET_MEMBER_ROLE_INVITE", params.role);
  },
  rmvLicenseAvailable(context) {
    context.commit("SET_MEMBER_MODULE_INVITE", "");
    context.commit("SET_MEMBER_ROLE_INVITE", "");
  },
  selectModuleAddLicense(context, params) {
    context.commit("SET_ADD_LICENSE_MODAL_MODULE", params);
  },
  selectModuleAddRole(context, params) {
    context.commit("SET_ADD_LICENSE_MODAL_ROLE", params);
  },
  exportMembers2Excel(context) {
    let users = context.getters.getMembers;
    var workbook = new ExcelJS.Workbook();
    var sheet = workbook.addWorksheet("Membros do Grupo");
    for (let indexU = 0; indexU < users.length; indexU++) {
      sheet.getColumn(1).values = ["ID"];
      sheet.getColumn(2).values = ["Nome"];
      sheet.getColumn(3).values = ["Email"];
      sheet.getColumn(4).values = ["Licença/Cargo"];
      sheet.getColumn(5).values = ["Escritorio"];
      sheet.getColumn(6).values = ["Razão Social"];
      sheet.getColumn(7).values = ["Nome Referencia"];
      sheet.getColumn(8).values = ["CNPJ"];

      sheet.getRow(indexU + 2).values = [
        users[indexU].id,
        users[indexU].name,
        users[indexU].email,
        users[indexU].licenses.length > 0
          ? users[indexU].licenses[0].module +
            "/" +
            users[indexU].licenses[0].role
          : "----/----",
        users[indexU].office ? "Vinculado" : "------",
        users[indexU].office ? users[indexU].office.corp_name : "------",
        users[indexU].office ? users[indexU].office.ref_name : "------",
        users[indexU].office ? users[indexU].office.corp_id : "------",
      ];
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Group_members-" + new Date().valueOf() + ".xlsx");
    });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {
  getAddMemberSelectedRole(state) {
    let role =
      state.licensesAvailable[state.indexLicenseSelected].roles[
        state.addMembersInvite.role - 1
      ];
    return role ? role.id : 0;
  },
  getSelectedModule(state) {
    return state.licensesAvailable[state.indexLicenseSelected];
  },
  getAddMemberValidation(state) {
    if (
      (state.addMembersInvite.emails.length > 0 &&
        state.addMembersInvite.role &&
        state.addMembersInvite.module) ||
      (state.addMembersInvite.emails.length > 0 &&
        state.addMembersInvite.role === 0 &&
        state.addMembersInvite.module === 0)
    ) {
      return true;
    } else {
      return false;
    }
  },
  getAddLicenseValidation(state) {
    if (
      state.addLicensesModal.module != null &&
      state.addLicensesModal.role != null
    ) {
      return true;
    } else {
      return false;
    }
  },
  getAddMemberPayload(state) {
    let module = state.licensesAvailable[state.addMembersInvite.module - 1];
    module
      ? (module.role = module.roles[state.addMembersInvite.role - 1])
      : (module = false);
    return module;
  },
  getMemberLicensesAvailable(state) {
    if (state.addLicensesModal.user.licenses) {
      let userModules = [];
      state.addLicensesModal.user.licenses.forEach((userLicense) => {
        userModules.push(userLicense.module_id);
      });
      return state.licensesAvailable.filter(
        (license) => !userModules.includes(license.module_id)
      );
    } else {
      return [];
    }
  },
  getMemberData(state) {
    return state.member;
  },

  getMembers(state) {
    let members = state.members;
    let filters = state.filters;
    if (filters.search.length > 2) {
      let reg = new RegExp(filters.search, "gi");
      members = members.filter(
        (member) =>
          reg.test(member.name) ||
          reg.test(member.email) ||
          reg.test(member.office ? member.office.ref_name : "")
      );
    }
    if (filters.role.length > 0) {
      members = members.filter((member) => {
        let userRoles = [];
        member.licenses.forEach((license) => {
          userRoles.push(license.module + "-" + license.role);
        });
        return userRoles.includes(filters.role[0]);
      });
    }
    return members;
  },
  getFilterLicenses(state) {
    let licenses = [];
    state.licensesAvailable.forEach((license) => {
      let group = {
        label: "Modulo: " + license.module,
        options: [],
      };
      license.roles.forEach((role) => {
        let option = {
          label: role.name,
          value: license.module + "-" + role.name,
        };
        group.options.push(option);
      });
      licenses.push(group);
    });

    return licenses;
  },

  getOfficeModal(state) {
    return state.officeModal.user.office;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
