import axios from "axios";
import store from "../store";
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

const axiosInstance = axios.create({
  headers: {
    "enl-token": process.env.VUE_APP_MODULE_TOKEN,
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = store.getters["auth/getToken"];
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
      return config;
    } else {
      delete config.headers.common["Authorization"];
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const api = {
  async get(endpoint) {
    return await axiosInstance.get(endpoint);
  },
  async post(endpoint, body) {
    return await axiosInstance.post(endpoint, body);
  },
  async put(endpoint, body) {
    return await axiosInstance.put(endpoint, body);
  },
  async delete(endpoint) {
    return await axiosInstance.delete(endpoint);
  },
};
