<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media container">
              <div class="row mr-6">
                <span
                  id="user-avatar"
                  :title="fullname"
                  class="avatar avatar rounded-circle bg-gradient-primary"
                  style="
                    box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 35%) !important;
                  "
                >
                  {{ acronym }}
                </span>
                <span
                  id="group-avatar"
                  :title="get__groupFullName"
                  class="avatar mb-4 avatar-sm rounded-circle bg-gradient-success"
                  style="
                    box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 35%) !important;
                  "
                >
                  {{ getGroupAcronym }}
                </span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Olá, {{ user.fname }}</h6>
          </div>
          <router-link to="/profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>Meu Perfil</span>
          </router-link>
          <a href="#" class="dropdown-item" @click.prevent="this.fetchGroups()">
            <i class="ni ni-settings-gear-65"></i>
            <span>Trocar Grupo</span>
          </a>
          <router-link to="/notifications" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Notificações</span>
          </router-link>
          <router-link to="/help" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Suporte</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link to="/logout" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Sair</span>
          </router-link>
        </base-dropdown>
      </li>
    </ul>

    <!-- MODAL GROUPS -->
    <modal
      v-model:show="modalGroups"
      gradient="primary"
      modal-classes="modal-danger modal-dialog-centered"
      style="z-index: 10000"
      local="groups"
    >
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-notification">Grupo Ativo</h6>
      </template>
      <div class="pb-3 text-center">
        <h4 class="heading my-4">Escolha o grupo que deseja ativar.</h4>
        <div class="form">
          <form>
            <fieldset class="form__options" v-if="groupsAvailable.length > 0">
              <p
                v-for="(group, index) in groupsAvailable"
                :key="index"
                :title="group.name"
                class="form__answer"
                @click="this.selectGroup(group.id)"
              >
                <input
                  type="radio"
                  :id="'group-' + group.id"
                  :value="'group-' + group.id"
                  v-model="selectedGroup.ref"
                />
                <label
                  :for="'group-' + group.id"
                  class="avatar bg-gradient-primary choose"
                >
                  {{
                    group.name[0].toUpperCase() + group.name[1].toUpperCase()
                  }}
                </label>
              </p>
            </fieldset>
          </form>
          <h4 class="heading my-4">
            {{ selectedGroup.name }} <br />
            {{ selectCorpId }}
          </h4>
        </div>
      </div>
      <template v-slot:footer>
        <button class="btn btn-info" disabled v-if="selectedGroup.initial">
          Salvar
        </button>
        <button
          class="btn btn-info"
          v-else
          @click.prevent.self="this.changeGroup()"
        >
          Salvar
        </button>
        <button
          @click.prevent="this.toggleModal('groups')"
          class="btn btn-danger ml-auto"
        >
          Cancelar
        </button>
      </template>
    </modal>
    <!-- END MODAL GROUPS -->
  </base-nav>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeNotifications: true,
      showMenu: false,
      searchQuery: "",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    ...mapActions("user", {
      fetchUser: "fetchUser",
      fetchGroups: "fetchGroups",
      selectGroup: "selectGroup",
      changeGroup: "changeGroup",
    }),
    ...mapActions("modals", {
      toggleModal: "toggleModal",
    }),
  },
  async mounted() {
    await this.fetchUser();
  },
  computed: {
    ...mapState("modals", {
      modalGroups: (state) => state.modalGroups,
    }),
    ...mapState("user", {
      user: (state) => state.user,
      selectedGroup: (state) => state.selectedGroup,
      groupsAvailable: (state) => state.groupsAvailable,
    }),
    ...mapGetters("user", {
      get__groupFullName: "get__groupFullName",
      getGroupAcronym: "getGroupAcronym",
      selectCorpId: "getSelectCorpId",
      acronym: "getUserAcronym",
      fullname: "getUserFullname",
    }),
  },
};
</script>

<style>
#group-avatar {
  cursor: pointer;
  position: absolute;
  margin-left: 30px;
  margin-top: -15px !important;
  border-width: 3px;
  border-style: solid;
  transition: 0.5s;
}
#group-avatar:hover {
  transition: 0.5s;
  padding: 18px;
}
#user-avatar {
  cursor: pointer;
  position: absolute;
  border-width: 3px;
  border-style: solid;
  transition: 0.5s;
}
#user-avatar:hover {
  transition: 0.5s;
  padding: 24px;
}
.dropdown-menu {
  margin-top: 55px !important;
  margin-left: -100px !important;
}
.form {
  margin: auto;
}

.form__answer {
  display: inline-block;
  vertical-align: center;
  font-size: 14px;
  text-align: center;
  margin: 5px;
}

label.choose {
  border: 6px solid;
  box-sizing: border-box;
  padding: 35px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
  border-radius: 100%;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 25%);
}

label.choose:hover,
label.choose:focus,
label.choose:active {
  padding: 50px;
  transition: all 0.3s ease-in-out;
  border: 6px solid rgb(96, 247, 134) !important;
  opacity: 0.8;
}

/* Input style */
input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="radio"]:active ~ label.choose {
  opacity: 1;
}

input[type="radio"]:checked ~ label.choose {
  padding: 50px;
  opacity: 1;
  border: 6px solid rgb(96, 247, 134) !important;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 35%);
}
</style>
