<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/wworkgroup.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-7"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Bem vindo {{ group.name }},</h1>
            <p class="text-white mt-0 mb-5">
              Um espaço especial para seu grupo no portal do ben.
            </p>
            <div v-if="isOwner == 'isOwner'">
              <a
                v-if="!showForm"
                href="#"
                @click.prevent="showForm = !showForm"
                class="btn btn-info"
              >
                Editar Informações
              </a>
              <a
                v-else
                href="#"
                @click.prevent="showForm = !showForm"
                class="btn btn-default"
              >
                Fechar Informações</a
              >
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- PRIFLE FORM -->
      <div class="row">
        <transition name="fade" class="">
          <div
            id="profile-form"
            class="col-12 order-xl-2 order-xs-2 mb-7"
            v-if="showForm"
          >
            <card shadow type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Meus dados</h3>
                    </div>
                    <div class="col-4 text-right">
                      <a href="#!" class="btn btn-sm btn-primary">Segurança</a>
                    </div>
                  </div>
                </div>
              </template>

              <form>
                <h6 class="heading-small text-muted mb-4">
                  Informações Pessoais
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <label for="name" class="form-control-label">Nome</label>
                      <input
                        type="text"
                        v-model="group.name"
                        class="form-control form-control-alternative"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <label for="lname" class="form-control-label">CNPJ</label>
                      <input
                        type="text"
                        v-model="group.ident_number"
                        class="form-control form-control-alternative"
                        v-maska="'##.###.###/####-##'"
                        @input="updateIdentNumber"
                      />
                    </div>
                    <div class="col-lg-6 mt-2"></div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Informações de Endereço
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <label for="zipcode" class="form-control-label"
                        >CEP</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative is-invalid"
                        v-maska="'#####-###'"
                        :value="group.address.zipcode"
                        @input="updateGroupZipcode"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8 mb-2">
                      <label for="street" class="form-control-label">Rua</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="group.address.street"
                        @input="updateStreet"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="zipcode" class="form-control-label">N°</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="group.address.number"
                        @input="updateNumber"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-2">
                      <label for="district" class="form-control-label"
                        >Bairro</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="group.address.district"
                        @input="updateDistrict"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="city" class="form-control-label"
                        >Cidade</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        disabled
                        :value="group.address.city"
                        @input="updateCity"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="state" class="form-control-label"
                        >Estado</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        disabled
                        :value="group.address.state"
                        @input="updateState"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Description -->
                <h6 id="aboutme-form" class="heading-small text-muted mb-4">
                  Salvar
                </h6>
                <div class="pl-lg-4 tex-center">
                  <a
                    href="#"
                    @click.prevent="saveAddress"
                    class="btn btn-success btn-block"
                  >
                    Salvar Alterações</a
                  >
                </div>
              </form>
            </card>
          </div>
        </transition>
      </div>

      <!-- GROUP DATA -->
      <div class="row">
        <div class="col-12 order-xs-1 mb-5">
          <div class="card card-profile shadow">
            <div class="row">
              <div
                class="col-12 order-lg-2 d-flex align-items-center justify-content-center"
              >
                <div
                  v-if="group.image == '54564654'"
                  class="card-profile-image d-flex align-items-center"
                  :title="group.name"
                >
                  <img
                    id="profile-avatar"
                    :src="api_url + group.image.url"
                    class="rounded-circle border-white"
                    style="
                      box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%) !important;
                    "
                  />
                </div>
                <span
                  v-else
                  id="profile-avatar"
                  :title="group.name"
                  class="avatar rounded-circle bg-gradient-success border-white card-profile-replace"
                  style="
                    padding: 75px;
                    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%) !important;
                  "
                  >{{ groupAcronym }}
                </span>

                <el-tooltip
                  effect="dark"
                  placement="bottom-start"
                  style="cursor: help"
                >
                  <template #content>
                    <!-- GROUP ADM -->
                    <div>
                      <strong>Administrador do Grupo</strong>:
                      {{ ownerFullname }} <br />
                    </div>
                    <!-- GROUP MANAGERS -->
                    <div
                      v-for="(manager, index) in group.managers"
                      :key="index"
                    >
                      <strong>Gerente de Grupo {{ index + 1 }}</strong
                      >:
                      {{ manager.username }}
                    </div>
                  </template>
                  <span
                    id="group-avatar-owner"
                    class="avatar rounded-circle bg-gradient-primary card-profile-replace"
                    style="
                      box-shadow: 1px 2px 3px 1px rgb(0 0 0 / 20%) !important;
                    "
                    >{{ ownerAcronym }}
                  </span>
                </el-tooltip>
              </div>
            </div>

            <div class="card-body pt-6 px-0">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-around mt-5"
                  >
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Notificações</span>
                    </div>
                    <div>
                      <span class="heading"
                        >{{ group.total_users }} / {{ group.max_users }}</span
                      >
                      <span class="description">Membros</span>
                    </div>
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Mensagens</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ group.name }} <br />
                  <span> {{ corpId }} </span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>
                  <span>{{ groupAddressLine }}</span>
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>
                </div>
                <hr />
                <div>
                  <h3>Licenças atribuidas a mim</h3>
                  <div
                    v-if="getMemberData"
                    class="avatar-group d-flex flex-wrap justify-content-around mb-4"
                  >
                    <div
                      class="m-2"
                      v-for="(license, index) in getMemberData"
                      :key="index"
                    >
                      <button
                        class="avatar avatar-lg rounded-circle bg-gradient-warning"
                        :title="license.name"
                      >
                        <i :class="license.icon"></i>
                      </button>
                      <div>
                        <strong class="text-default">{{
                          license.module
                        }}</strong>
                      </div>
                      <small>{{ license.role }}</small>
                    </div>
                  </div>
                </div>
                <members-table
                  v-if="isOwner == 'isOwner'"
                  title="Membros"
                ></members-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import membersTable from "../Tables/membersTable";

export default {
  components: {
    membersTable,
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
    ...mapActions("group", {
      fetchGroup: "fetchGroup",
      updateGroupZipcode: "updateGroupZipcode",
      updateStreet: "updateStreet",
      updateCity: "updateCity",
      updateState: "updateState",
      updateAddress: "updateAddress",
      updateNumber: "updateNumber",
      updateIdentNumber: "updateIdentNumber",
    }),
    ...mapActions("groupMembers", {
      fetchMember: "fetchMember",
    }),
    saveAddress() {
      this.showForm = !this.showForm;
      this.updateAddress();
    },
  },
  computed: {
    ...mapState("group", {
      group: (state) => state.group,
    }),
    ...mapGetters("group", {
      ownerAcronym: "getOwnerAcronym",
      ownerFullname: "getOwnerFullname",
      groupAcronym: "getGroupAcronym",
      groupAddressLine: "getGroupAddressLine",
      corpId: "getCorpId",
      isOwner: "getIsOwner",
    }),
    ...mapGetters("groupMembers", {
      getMemberData: "getMemberData",
    }),
  },
  mounted() {
    this.fetchGroup();
    this.fetchMember();
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#profile-avatar {
  cursor: pointer !important;
  border-width: 12px;
  border-style: solid;
  font-size: 45px;
}
#group-avatar-owner {
  cursor: pointer !important;
  border-width: 10px;
  border-style: solid;
  font-size: 25px;
  padding: 35px;
  z-index: 0;
  position: absolute;
  margin: 150px 0px 0px 70px;
  font-weight: 400;
}
</style>
