import { api } from "@/services";
import router from "../../router";

// ONE TRUE SOURCE
const state = {
  loadingStatus: "notLoading",
  sponsorToken: "",
  isOffice: true,
  sponsorGroup: {
    corp_id: "*",
    corp_name: "*",
    image_replace: "*",
  },
  entityData: {},
  user: {
    fname: "",
    lname: "",
    phone: "",
    email: "",
    password: "",
    terms_use: "",
  },
  corp: {
    id: "",
    name: "",
  },
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_FNAME(state, payload) {
    state.user.fname = payload;
  },
  SET_LNAME(state, payload) {
    state.user.lname = payload;
  },
  SET_EMAIL(state, payload) {
    state.user.email = payload;
  },
  SET_PHONE(state, payload) {
    state.user.phone = payload;
  },
  SET_PASSWORD(state, payload) {
    state.user.password = payload;
  },
  SET_TERMS_USE(state, payload) {
    state.user.terms_use = payload;
  },
  SET_TYPE(state, payload) {
    state.user.type = payload;
  },
  SET_CORP_NAME(state, payload) {
    state.corp.name = payload;
  },
  SET_CORP_ID(state, payload) {
    state.corp.id = payload;
  },
  SET_SPONSOR_TOKEN(state, payload) {
    state.sponsorToken = payload;
  },
  SET_ENTITY_DATA(state, payload) {
    state.entityData = payload;
  },
  SET_SPONSOR_GROUP(state, payload) {
    state.sponsorGroup = payload;
  },
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_OFFICE(state, payload) {
    state.isOffice = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async inputSponsorToken(context, token) {
    context.commit("SET_SPONSOR_TOKEN", token);
    await context.dispatch("fetchSponsorGroup");
  },
  async fetchSponsorGroup(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    let token = context.state.sponsorToken;
    await api
      .get("/group-invites?token=" + token)
      .then((response) => {
        context.commit("SET_SPONSOR_GROUP", response.data[0].group);
        context.commit("SET_EMAIL", response.data[0].email);
        context.commit("SET_LOADING_STATUS", "notloading");
        context.commit("SET_ENTITY_DATA", response.data[0]);
      })
      .catch(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, você precisa de um convite valido para se cadastrar.",
          },
          { root: true }
        );
        router.push({ name: "login" });
      });
  },
  async sendForm(context, corpData) {
    context.commit("SET_LOADING_STATUS", "loading");
    const form = {
      sponsorToken: context.state.sponsorToken,
      fname: context.state.user.fname,
      lname: context.state.user.lname,
      phone: context.state.user.phone,
      email: context.state.user.email,
      terms_use: context.state.user.terms_use,
      password: context.state.user.password,
      office: context.state.isOffice,
      corp: context.state.corp,
      corpData: corpData,
    };
    console.log(form);
    await api
      .post("users-infos/register", form)
      .then(() => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message:
              "Cadastro efetuado com sucesso, por favor insira suas credenciais.",
          },
          { root: true }
        );
        router.push({ name: "login" });
      })
      .catch((error) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: error.response
              ? error.response.data.message
              : "Desculpe, você não pode acessar este grupo.",
          },
          { root: true }
        );
      });
  },
  updateFname(context, event) {
    context.commit("SET_FNAME", event.target.value.trim());
  },
  updateLname(context, event) {
    context.commit("SET_LNAME", event.target.value.trim());
  },
  updateEmail(context, event) {
    context.commit("SET_EMAIL", event.target.value.trim());
  },
  updateTermsUse(context, event) {
    console.log("aqui", event.target.value);
    context.commit("SET_TERMS_USE", event.target.checked);
  },
  updatePhone(context, event) {
    context.commit("SET_PHONE", event.target.value);
  },
  updatePassword(context, event) {
    context.commit("SET_PASSWORD", event.target.value);
  },
  updateCorpId(context, event) {
    context.commit("SET_CORP_ID", event.target.value);
  },
  updateCorpName(context, event) {
    context.commit("SET_CORP_NAME", event.target.value);
  },
  updateOffice(context, event) {
    context.commit("SET_OFFICE", event.target.checked);
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {
  passwdScore(state) {
    let password = state.user.password;
    let score = 0;
    let letters = new Object();
    for (let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }
    let variations = {
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += variations[check] == true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return parseInt(score);
  },

  formValidation(state, getters) {
    let validation = {
      fname: state.user.fname.length > 2 ? "has-success" : "fail",
      lname: state.user.lname.length > 2 ? "has-success" : "fail",
      phone: state.user.phone.length > 13 ? "has-success" : "fail",
      password: getters.passwdScore >= 55 ? "has-success" : "fail",
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.user.email)
        ? "has-success"
        : "fail",
    };
    if (state.isOffice) {
      validation.corp_id = state.corp.id.length > 13 ? "has-success" : "fail";
      validation.corp_name =
        state.corp.name.length > 4 ? "has-success" : "fail";
    }
    let success = 0;
    Object.values(validation).forEach((input) => {
      input === "has-success" ? success++ : "";
    });
    validation.total = success == Object.keys(validation).length ? true : false;

    return validation;
  },
  getGroupAcronym(state) {
    return (
      state.sponsorGroup.corp_name[0] + state.sponsorGroup.corp_name[1]
    ).toUpperCase();
  },
  getEntityData(state) {
    return state.entityData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
