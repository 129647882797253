<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 pb-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="btn-wrapper text-center">Credenciais Portal do Ben</div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" action="#">
            <!-- USUARIO -->
            <div class="form-group input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-email-83"></i>
                </span>
              </div>
              <input
                placeholder="Email"
                class="form-control"
                type="email"
                required
                aria-describedby="addon-right addon-left"
                v-model="username"
              />
            </div>
            <!-- SENHA -->
            <div class="form-group input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                placeholder="Senha"
                class="form-control"
                type="password"
                required
                aria-describedby="addon-right addon-left"
                v-model="password"
              />
            </div>
            <!-- SEND -->
            <div class="text-center my-5">
              <!-- NORMAL STATE -->
              <div class="text-center">
                <button
                  v-if="loading == 'loading'"
                  disabled
                  class="btn btn-primary btn-block"
                >
                  <i class="el-icon-loading"></i>
                </button>
                <button
                  v-else
                  class="btn btn-primary btn-block"
                  @click.prevent="this.sendForm()"
                >
                  Acessar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/recovery" class="text-light"
            ><small>Esqueceu a senha?</small></router-link
          >
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Não tem uma conta?</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  props: {
    dataSuccessMessage: {
      type: String,
    },
  },
  data() {
    return {
      username: "",
      password: "",
      loading: "notLoading",
    };
  },
  methods: {
    sendForm() {
      this.loading = "loading";
      this.$store.dispatch("auth/sendCredentials", {
        username: this.username,
        password: this.password,
      });
    },
  },
  created() {
    this.$store.watch(
      (state) => state["auth"].loginStatus,
      (loginStatus) => {
        switch (loginStatus) {
          case "auth":
            this.$store.dispatch("toast/sendMessage", {
              type: "success",
              message: "Dados autenticados com sucesso. ",
            });
            this.loading = "notLoading";
            this.$router.push({ name: "Modulos" });
            break;
          case "noAuth":
            this.loading = "notLoading";
            this.$store.dispatch("toast/sendMessage", {
              type: "error",
              message: "Email ou senha incorretos.",
            });
            break;
          default:
            break;
        }
      }
    );
  },
};
</script>
<style></style>
