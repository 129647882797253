<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(../img/theme/newspaper.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-7"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Noticias do dia</h1>
            <p class="text-white mt-0 mb-5">
              noticias selecionadas para ficar por dentro do que esta
              acontecendo no mundo do direito.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Ultimas noticias</h3>
                  </div>
                </div>
              </div>
            </template>
            <!-- END -->
            <div class="container-fluid">
              <div class="col-12 p-0">
                <div
                  class="d-flex d-flex justify-content-center flex-wrap"
                  v-if="news.length > 0"
                >
                  <div
                    class="col-xl-5 col-lg-6 col-md-10 col-sm-12"
                    v-for="(item, index) in news"
                    :key="index"
                  >
                    <div class="profile">
                      <figure>
                        <img
                          v-if="item.image"
                          :src="item.image"
                          :alt="item.title"
                          style="width: 200%; height: 100%; object-fit: fill"
                        />
                        <img
                          v-else
                          src="https://i.imgur.com/Z7xg6iA.jpg"
                          style="width: 200%; height: 100%; object-fit: fill"
                          :alt="item.title"
                        />
                      </figure>
                      <div class="data">
                        <h3 class="">
                          <i class="fas fa-newspaper status-icon"></i>
                          <span>{{ item.title }}</span>
                        </h3>
                        <ul>
                          <li><strong>Fonte:</strong> {{ item.source }}</li>
                          <li><strong>Autor:</strong> {{ item.author }}</li>
                          <li>
                            <strong>Publicado:</strong>
                            {{
                              this.moment(item.published_date).format(
                                "DD/MM/YY"
                              )
                            }}
                          </li>
                          <li>
                            <strong>Descrição:</strong> {{ item.description }}
                          </li>
                        </ul>
                        <a
                          target="_blank"
                          :href="item.original_url"
                          class="btn btn-success btn-block btn-module mb-2"
                        >
                          Ver notícia completa
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-skeleton style="width: 240px" animated>
                    <template #template>
                      <el-skeleton-item
                        variant="image"
                        style="width: 240px; height: 240px"
                      />
                      <div style="padding: 14px">
                        <el-skeleton-item variant="p" style="width: 50%" />
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-items: space-between;
                          "
                        >
                          <el-skeleton-item
                            variant="text"
                            style="margin-right: 16px"
                          />
                          <el-skeleton-item variant="text" style="width: 30%" />
                        </div>
                      </div>
                    </template>
                  </el-skeleton>
                </div>
              </div>
            </div>
            <!-- END -->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Newspaper",
  data() {
    return {};
  },
  methods: {
    moment: function () {
      return moment();
    },
    ...mapActions("newspaper", {
      fetchNews: "fetchNews",
    }),
  },
  computed: {
    ...mapState("newspaper", {
      news: (state) => state.news,
    }),
  },
  mounted() {
    this.fetchNews();
  },
};
</script>
<style>
strong {
  font-weight: 700;
  color: #fff;
}

.no-wrap {
  white-space: nowrap;
}

.btn-module {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgb(23, 43, 77);
  border: none;
}

.led-green {
  width: 4px;
  height: 4px;
  background-color: rgba(45, 206, 134, 1);
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  box-shadow: 0px 0px 5px 2px rgba(45, 206, 134, 0.25);
}

.profile {
  height: 315px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}
.profile .status-icon {
  display: block;
  font-size: 22px;
  border-radius: 100%;
  background: rgba(23, 43, 77, 0.5);
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
}
.profile figure {
  width: 100%;
  margin: 0;
}
.profile figure img {
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.gray-img {
  filter: grayscale(100%);
}
.profile .data {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 65%;
  transition: 0.3s ease-out;
  background: rgba(23, 43, 77, 0);
  background: -moz-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(23, 43, 77, 0)),
    color-stop(35%, rgba(23, 43, 77, 0.82)),
    color-stop(76%, rgba(23, 43, 77, 1)),
    color-stop(100%, rgba(23, 43, 77, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6B7AE1', endColorstr='#7F60D8', GradientType=0 );
}
.profile:hover .data {
  top: 3%;
  cursor: pointer;
}
.profile .data h3 {
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  min-height: 100px;
  padding: 0 15px;
}
.profile .data ul {
  padding: 0 15px;
  list-style-type: none;
}
.profile .data ul li {
  font-weight: 300;
  color: #ddd;
  margin: 0 0 6px 0;
  font-size: 12px;
}
</style>
