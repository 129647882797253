<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 pb-9">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="btn-wrapper text-center">Insira sua nova senha</div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" action="#" @submit.prevent="recovery">
            <!-- USUARIO -->
            <div class="form-group input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="ni ni-lock-circle-open"></i>
                </span>
              </div>
              <input
                placeholder="Nova Senha"
                class="form-control"
                type="password"
                required
                aria-describedby="addon-right addon-left"
                v-model="password"
              />
            </div>
            <!-- SEND -->
            <div class="text-center my-5">
              <!-- NORMAL STATE -->
              <div class="text-center">
                <button
                  v-if="this.loading"
                  disabled
                  class="btn btn-primary btn-block"
                >
                  <i class="el-icon-loading"></i>
                </button>
                <button
                  v-else
                  class="btn btn-primary btn-block"
                  @click.prevent="this.sendForm()"
                >
                  Confirmar Nova Senha
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/login" class="text-light"
            ><small>Lembrei minha senha?</small></router-link
          >
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Não tem uma conta?</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "password",
  data() {
    return {
      token: this.$route.params.token,
      password: "",
      loading: false,
    };
  },
  methods: {
    async sendForm() {
      this.loading = true;
      let newPass = this.password;
      if (newPass.length > 5) {
        await this.$store.dispatch("auth/sendNewPass", {
          token: this.token,
          password: newPass,
        });
        this.loading = false;
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("toast/sendMessage", {
          type: "error",
          message: "Por favor, digite um senha mais dificil.",
        });
        this.loading = false;
      }
    },
  },
};
</script>
<style></style>
