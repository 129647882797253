<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          <a class="navbar-brand" href="/modules">
            <img src="https://enl-public.nyc3.digitaloceanspaces.com/logos/LogoHorizontalAzul.png" style="max-width: 100%; height: 100px;" alt="Portal do Ben" />
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a href="https://be-enlighten.com" class="nav-link" target="_blank"
              >Suporte</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://be-enlighten.com/presentation"
              class="nav-link"
              target="_blank"
              >Sobre nós</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://be-enlighten.com/blog"
              class="nav-link"
              target="_blank"
              >Blog</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://be-enlighten.com/license"
              class="nav-link"
              target="_blank"
              >Politicas</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style></style>
