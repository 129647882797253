<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Olá {{ user.fname }},</h1>
            <p class="text-white mt-0 mb-5">
              Aqui estão as informações da sua conta, mantenha seus dados
              atualizados para não perder nenhuma atividade do seu grupo.
            </p>
            <a
              v-if="!showForm"
              href="#"
              @click.prevent="showForm = !showForm"
              class="btn btn-info"
            >
              Editar Informações
            </a>
            <a
              v-else
              href="#"
              @click.prevent="showForm = !showForm"
              class="btn btn-default"
            >
              Fechar Informações</a
            >
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- PRIFLE FORM -->
      <div class="row">
        <transition name="fade" class="">
          <div
            id="profile-form"
            class="col-12 order-xl-2 order-xs-2 mb-7"
            v-if="showForm"
          >
            <card shadow type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">Meus dados</h3>
                    </div>
                    <div class="col-4 text-right">
                      <button
                        @click="toggleModal('securityUser')"
                        class="btn btn-sm btn-primary"
                      >
                        Segurança
                      </button>
                    </div>
                  </div>
                </div>
              </template>

              <form>
                <h6 class="heading-small text-muted mb-4">
                  Informações Pessoais
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <label for="username" class="form-control-label"
                        >Usuário</label
                      >
                      <input
                        type="text"
                        v-model="user.user.username"
                        class="form-control form-control-alternative"
                        disabled
                      />
                    </div>
                    <div class="col-lg-6 mt-2">
                      <label for="email" class="form-control-label"
                        >Endereço de Email</label
                      >
                      <input
                        type="email"
                        v-model="user.user.username"
                        class="form-control form-control-alternative"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <label for="fname" class="form-control-label">Nome</label>
                      <input
                        type="text"
                        v-model="user.fname"
                        class="form-control form-control-alternative"
                        disabled
                      />
                    </div>
                    <div class="col-lg-6 mt-2">
                      <label for="lname" class="form-control-label"
                        >Sobrenome</label
                      >
                      <input
                        type="text"
                        v-model="user.lname"
                        class="form-control form-control-alternative"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mt-2">
                      <label for="lname" class="form-control-label">CPF</label>
                      <input
                        type="text"
                        v-model="user.ident_number"
                        class="form-control form-control-alternative"
                        v-maska="['###.###.###-##', '##.###.###/####-##']"
                      />
                    </div>
                    <div class="col-lg-6 mt-2"></div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Informações de Endereço
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <label for="zipcode" class="form-control-label"
                        >CEP</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative is-invalid"
                        v-maska="'#####-###'"
                        :value="user.user_address.zipcode"
                        @input="updateZipcode"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8 mb-2">
                      <label for="street" class="form-control-label">Rua</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="user.user_address.street"
                        @input="updateStreet"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="number" class="form-control-label">N°</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="user.user_address.number"
                        @input="updateNumber"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-2">
                      <label for="district" class="form-control-label"
                        >Bairro</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        :value="user.user_address.district"
                        @input="updateDistrict"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="city" class="form-control-label"
                        >Cidade</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        disabled
                        :value="user.user_address.city"
                        @input="updateCity"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="state" class="form-control-label"
                        >Estado</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        disabled
                        :value="user.user_address.state"
                        @input="updateState"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Description -->
                <h6 id="aboutme-form" class="heading-small text-muted mb-4">
                  Sobre
                </h6>
                <div class="pl-lg-4 text-center">
                  <a
                    href="#"
                    @click.prevent="saveAddress"
                    class="btn btn-success btn-block"
                  >
                    Salvar Alterações</a
                  >
                </div>
              </form>
            </card>
          </div>
        </transition>
      </div>

      <!-- PROFILE DATA -->
      <div class="row">
        <div class="col-12 order-xs-1 mb-5">
          <div class="card card-profile shadow">
            <div class="row">
              <div
                class="col-12 order-lg-2 d-flex align-items-center justify-content-center"
              >
                <div
                  v-if="user.image"
                  class="card-profile-image d-flex align-items-center"
                  :title="fullname"
                >
                  <img
                    id="profile-avatar"
                    :src="user.image"
                    class="rounded-circle border-white"
                    style="
                      box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%) !important;
                    "
                  />
                </div>
                <span
                  v-else
                  id="profile-avatar"
                  :title="fullname"
                  class="avatar rounded-circle bg-gradient-primary border-white card-profile-replace"
                  style="
                    padding: 75px;
                    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%) !important;
                  "
                >
                  {{ acronym }}
                </span>
                <span
                  @click="this.fetchGroups()"
                  id="group-avatar-user"
                  :title="user.group.name"
                  class="avatar rounded-circle bg-gradient-success card-profile-replace"
                  style="
                    box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%) !important;
                  "
                >
                  {{ groupAcronym }}
                </span>
              </div>
            </div>
            <div class="card-body pt-6">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-around mt-5"
                  >
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Notificações</span>
                    </div>
                    <div>
                      <span class="heading">--</span>
                      <span class="description">Grupo</span>
                    </div>
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Mensagens</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ fullname }}
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>
                  <span v-if="user.user_address">{{ addressLine }}</span>
                  <span v-else>--, --</span>
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ user.module_role.name }}@{{ user.group.name }}
                </div>
                <hr class="my-4" />
                <p v-if="user.about">
                  {{ user.about }}
                </p>
                <p v-else>
                  <a href="#aboutme-form">Conte algo sobre você</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL SECURITY -->
  <modal
    v-model:show="modalSecurityUser"
    gradient="default"
    modal-classes="modal-danger modal-dialog-centered modal-lg"
    local="securityUser"
  >
    <template v-slot:header>
      <h6 class="modal-title" id="modal-title-notification">
        Dados de Segurança.
      </h6>
    </template>
    <form role="form">
      <div class="row">
        <div class="col-12">
          <div class="font-weight-bold mb-3">Senha Atual</div>
        </div>
      </div>
      <!-- ENTITY REF NAME -->
      <div class="form-group input-group input-group-alternative">
        <div class="input-group-prepend">
          <span class="input-group-text"
            ><i class="text-primary fa fa-lock" title="Senha Atual"></i
          ></span>
        </div>
        <input
          type="password"
          placeholder="Senha Atual"
          class="form-control"
          v-model="securityForm.currentPassword"
          aria-describedby="addon-right addon-left"
        />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="font-weight-bold mb-3">Nova Senha</div>
        </div>
      </div>
      <!-- ENTITY REF NAME -->
      <div class="form-group input-group input-group-alternative">
        <div class="input-group-prepend">
          <span class="input-group-text"
            ><i class="text-primary fa fa-lock" title="Senha Nova"></i
          ></span>
        </div>
        <input
          type="password"
          placeholder="Nova Senha"
          class="form-control"
          :value="get__newPassword"
          @input="updateNewPassword"
          aria-describedby="addon-right addon-left"
        />
      </div>
      <base-alert
        type="primary"
        v-if="get__newPasswdScore < 55 && get__newPasswdScore > 1"
      >
        Para aumentar o nivel de segurança use:
        <ul>
          <li>Ao menos um Numero</li>
          <li>Ao menos uma letra Maiuscula</li>
          <li>Ao menos uma letra Minuscula</li>
          <li>Ao menos um caractere especial</li>
        </ul>
        <strong>Mínimo: 55/100</strong>
      </base-alert>
      <div class="text-muted font-italic mb-3" v-if="get__newPasswdScore > 1">
        <small
          >Nivel de segurança da senha
          <span>{{ get__newPasswdScore }} / 100</span>
          <br />
          <span
            v-if="get__newPasswdScore > 80"
                  class="text-success font-weight-700"
                  >Forte</span
                >
                <span
                  v-else-if="get__newPasswdScore >= 55"
                  class="text-info font-weight-700"
                  >Bom</span
                >
                <span
                  v-else-if="get__newPasswdScore >= 30"
                  class="text-warning font-weight-700"
                  >Fraco</span
                >
                <span
                  v-else-if="get__newPasswdScore > 5"
                  class="text-danger font-weight-700"
                  >Não recomendado</span
                >
                <br />
              </small>
            </div>
      <div class="row">
        <div class="col-12">
          <div class="font-weight-bold mb-3">Repetir Nova Senha</div>
        </div>
      </div>
      <!-- ENTITY REF NAME -->
      <div class="form-group input-group input-group-alternative">
        <div class="input-group-prepend">
          <span class="input-group-text"
            ><i class="text-primary fa fa-lock" title="Repetir Senha"></i
          ></span>
        </div>
        <input
          type="password"
          placeholder="Repetir Senha"
          class="form-control"
          v-model="securityForm.newConfirmation"
          aria-describedby="addon-right addon-left"
        />
      </div>
    </form>
    <template v-slot:footer>
      <button
        v-if="loadingStatus == 'notLoading'"
        :disabled="get__newPasswdScore < 55"
        class="btn btn-success"
        @click="updatePassword(securityForm)"
      >
        Atualizar
      </button>
      <button v-else class="btn btn-success" disabled>
        <i class="el-icon-loading"></i>
      </button>
      <button
        @click.prevent="this.toggleModal('securityUser')"
        class="btn btn-danger ml-auto"
      >
        Cancelar
      </button>
    </template>
  </modal>
  <!-- END MODAL ADD OFFICE  -->
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      api_url: process.env.VUE_APP_API_ENDPOINT,
      showForm: false,
      securityForm: {
        currentPassword: "",
        newPassword: "",
        newConfirmation: "",
      },
    };
  },
  methods: {
    ...mapActions("user", {
      updatePassword: "updatePassword",
      updateNewPassword: "updateNewPassword",
      fetchGroups: "fetchGroups",
      updateZipcode: "updateZipcode",
      updateStreet: "updateStreet",
      updateNumber: "updateNumber",
      updateDistrict: "updateDistrict",
      updateCity: "updateCity",
      updateState: "updateState",
      updateAddress: "updateAddress",
    }),
    ...mapActions("modals", {
      toggleModal: "toggleModal",
    }),
    saveAddress() {
      this.showForm = !this.showForm;
      this.updateAddress();
    },
  },
  computed: {
    ...mapState("user", {
      user: "user",
      loadingStatus: "loadingStatus",
    }),
    ...mapState("modals", {
      modalSecurityUser: "modalSecurityUser",
    }),
    ...mapGetters("user", {
      get__newPassword: "get__newPassword",
      get__newPasswdScore: "get__newPasswdScore",
      groupAcronym: "getGroupAcronym",
      acronym: "getUserAcronym",
      fullname: "getUserFullname",
      addressLine: "getUserAddressLine",
    }),
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#profile-avatar {
  cursor: pointer !important;
  border-width: 12px;
  border-style: solid;
  font-size: 45px;
}
#group-avatar-user {
  cursor: pointer !important;
  border-width: 12px;
  border-style: solid;
  font-size: 25px;
  padding: 45px;
  z-index: 0;
  position: absolute;
  margin: -140px 0px 0px 70px;
  font-weight: 400;
}
</style>
