<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div>
    <div
      id="scrollTerm"
      :class="`bg-${bgColor} text-${textColor}`"
      class="p-2"
      @scroll="handleScroll"
      style="
        height: 300px;
        overflow: auto;
        margin-bottom: 15px;
        border: 1px solid rgb(205 210 218);
      "
    >
      <p class="ql-align-center">
        <strong :class="`text-${textColor}`"
          >MASTER SUBSCRIPTION AGREEMENT</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        For a PDF version of this Master Subscription Agreement, please e-mail
        <a
          href="mailto:sales@be-enlighten.com"
          rel="noopener noreferrer"
          target="_blank"
          :style="`color: ${textColor}`"
          >sales@be-enlighten.com</a
        >&nbsp;&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >THIS AGREEMENT IS A BINDING CONTRACT AND GOVERNS THE USE OF AND
          ACCESS TO THE SERVICES BY YOU, AGENTS, AND END-USERS WHETHER IN
          CONNECTION WITH A PAID SUBSCRIPTION OR FREE TRIAL FOR THE
          SERVICES.</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >By accepting this Agreement, either by accessing or using a Service,
          or authorizing or permitting any Agent or End-User to access or use a
          Service, you agree to be bound by this Agreement as of the date of
          such access or use of the Service (the “Effective
          Date”).&nbsp;</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >If You are entering into this Agreement on behalf of a company,
          organization, or another legal entity (an “Entity”), You are agreeing
          to this Agreement for that Entity and representing to ENLIGHTEN that
          You have the authority to bind such Entity and its Affiliates to this
          Agreement, in which case the terms “Subscriber,” “You,” or “Your”
          herein refers to such Entity and its Affiliates.&nbsp;</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >If You do not have such authority, or if You do not agree with this
          Agreement, you must not use or authorize any use of the
          Services.&nbsp;</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >Subscriber and ENLIGHTEN shall each be referred to as a “Party” and
          collectively referred to as the “Parties” for purposes of this
          Agreement.</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        The purpose of this Agreement is to establish the terms and conditions
        under which Subscriber obtains ENLIGHTEN’s Services and Professional
        Services as described in a Service Order, Statement of Work or another
        document signed or agreed to by You.&nbsp;
      </p>
      <p class="ql-align-justify">
        In the event of any inconsistency or conflict between the terms of the
        Master Subscription Agreement and the terms of any Service Order or
        Statement of Work, the terms of the Service Order or Statement of Work
        shall control. Non-English translations of this Agreement are provided
        for convenience only.&nbsp;&nbsp;
      </p>
      <p class="ql-align-justify">
        In the event of any ambiguity or conflict between translations, the
        English version shall control.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">Table of Contents:</strong>&nbsp;
      </p>
      <p class="ql-align-justify">General Terms and Conditions:&nbsp;</p>
      <p class="ql-align-justify">Access to the Services&nbsp;</p>
      <p class="ql-align-justify">Use of the Services&nbsp;</p>
      <p class="ql-align-justify">Term, Cancellation and Termination&nbsp;</p>
      <p class="ql-align-justify">
        Billing, Plan Modification and Payments&nbsp;
      </p>
      <p class="ql-align-justify">Confidential Information&nbsp;</p>
      <p class="ql-align-justify">
        Sub-Processors and Security of Service Data&nbsp;
      </p>
      <p class="ql-align-justify">Temporary Suspension&nbsp;</p>
      <p class="ql-align-justify">Non-ENLIGHTEN Services&nbsp;</p>
      <p class="ql-align-justify">Free Trials&nbsp;</p>
      <p class="ql-align-justify">Intellectual Property Rights&nbsp;</p>
      <p class="ql-align-justify">
        Representations, Warranties and Disclaimers&nbsp;
      </p>
      <p class="ql-align-justify">Indemnification&nbsp;</p>
      <p class="ql-align-justify">Limitation of Liability&nbsp;</p>
      <p class="ql-align-justify">
        Assignment, Entire Agreement and Amendment&nbsp;
      </p>
      <p class="ql-align-justify">Severability&nbsp;</p>
      <p class="ql-align-justify">
        Export Compliance and Use Restrictions&nbsp;
      </p>
      <p class="ql-align-justify">Relationship of the Parties&nbsp;</p>
      <p class="ql-align-justify">Notice&nbsp;</p>
      <p class="ql-align-justify">Governing Law&nbsp;</p>
      <p class="ql-align-justify">
        Federal Government End Use Provisions&nbsp;
      </p>
      <p class="ql-align-center">
        <strong :class="`text-${textColor}`"
          >General Terms and Conditions</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 1. ACCESS TO THE SERVICES</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.1</strong> Service. We will make
        the Services and Your Service Data available to You pursuant to this
        Agreement and the applicable Service Order(s) and Documentation in
        accordance with Your Service Plan. We will use commercially reasonable
        efforts to make the Services available twenty-four (24) hours a day,
        seven (7) days a week, except during (a) Planned Downtime (of which We
        will give advance notice via Our Site or to the Account owner); and (b)
        Force Majeure Events.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.2</strong> Support. We will, at
        no additional charge, provide You standard customer support for the
        Services. We will provide You upgraded support if purchased.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.3</strong> Professional Services.
        Upon Your request, we may provide Professional Services subject to the
        terms and conditions stated at Professional Services Terms and
        Conditions.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.4</strong> Modifications. You
        acknowledge that ENLIGHTEN may modify the features and functionality of
        the Services during the Subscription Term. ENLIGHTEN shall provide You
        with thirty (30) days’ advance notice of any deprecation of any material
        feature or functionality. ENLIGHTEN will not materially decrease the
        overall functionality of the Services purchased by You during the
        Subscription Term.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.5</strong> Additional Features.
        We will notify You of applicable Supplemental Terms or alternate terms
        and conditions prior to Your activation of any Additional Features. The
        activation of any Additional Features by You in Your Account will be
        considered acceptance of the applicable Supplemental Terms or alternate
        terms and conditions where applicable.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">1.6</strong> Extension of Rights to
        Affiliates. You may extend Your rights, benefits and protections
        provided herein to Your Affiliates and to contractors or service
        providers acting on Your or Your Affiliates’ behalf, if You remain
        responsible for Your and their compliance hereunder.&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 2. USE OF THE SERVICES</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.1</strong> Login Management.
        Access to and use of certain Services is restricted, such as to the
        specified number of individual Agents permitted under Your subscription
        to the applicable Service, as detailed in the Documentation. For
        Services that are Agent-based, You agree and acknowledge that an Agent
        Login cannot be shared or used by more than one (1) individual per
        Account. However, Agent Logins may be reassigned to new individuals
        replacing former individuals who no longer require ongoing use of the
        Services. You and Your Agents are responsible for maintaining the
        confidentiality of all Agent Login information for Your Account. Absent
        a written license from ENLIGHTEN expressly stating otherwise, You agree
        and acknowledge that You may not use the Services, including but not
        limited to the API, to circumvent the requirement for an individual
        Agent Login for each individual who (a) leverages the Services to
        interact with End-Users; (b) Processes data related to interactions with
        End-Users; or (c) Processes data related to interactions originating
        from a Non-ENLIGHTEN Service that provides functionality similar to
        functionality provided by the Services and which would, pursuant to this
        Agreement, require an individual Agent Login, if utilizing the Services
        for such interaction. Further, Subscriber shall not use the API or any
        Software in such a way to circumvent applicable Service Plan
        restrictions or Agent licensing restrictions that are enforced in the
        Service user interface. ENLIGHTEN reserves the right to charge You, and
        You hereby agree to pay, for any overuse of a Service in violation of
        this Agreement or the Service Plan features and limitations on Our Site
        or Documentation, in addition to other remedies available to Us.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.2</strong> Compliance. As between
        You and ENLIGHTEN, you are responsible for compliance with the
        provisions of this Agreement by Agents and End-Users and for any and all
        activities that occur under Your Account, which ENLIGHTEN may verify
        from time to time. Without limiting the foregoing, you will ensure that
        Your use of the Services is compliant with all applicable laws and
        regulations as well as any and all privacy notices, agreements or other
        obligations You may maintain or enter into with Agents or
        End-Users.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.3</strong> Content and Conduct.
        You agree to comply with the ENLIGHTEN User Content and Conduct Policy
        (as defined below) which is hereby incorporated into this Agreement.
        Further, in Your use of the Services You agree not to (a) modify, adapt,
        or hack the Services or otherwise attempt to gain unauthorized access to
        the Services or related systems or networks; (b) attempt to bypass or
        break any security or rate limiting mechanism on any of the Services or
        use the Services in any manner that interferes with or disrupts the
        integrity, security or performance of the Services and its components;
        (c) attempt to decipher, decompile, reverse engineer or otherwise
        discover the source code of any Software making up the Services; or (d)
        to the extent You are subject to the US Health Insurance Portability and
        Accountability Act of 1996, and its implementing regulations (HIPAA),
        use the Services to store or transmit any “protected health information”
        as defined by HIPAA, unless expressly agreed to otherwise in writing by
        ENLIGHTEN.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.4</strong> System Requirements. A
        high-speed Internet connection is required for proper transmission of
        the Services. You are responsible for procuring and maintaining the
        network connections that connect Your network to the Services including,
        but not limited to, browser software that supports protocols used by
        ENLIGHTEN, including the Transport Layer Security (TLS) protocol or
        other protocols accepted by ENLIGHTEN, and to follow procedures for
        accessing services that support such protocols. We are not responsible
        for notifying You, Agents or End-Users of any upgrades, fixes or
        enhancements to any such software or for any compromise of data,
        including Service Data, transmitted across computer networks or
        telecommunications facilities (including but not limited to the
        Internet) which are not owned, operated or controlled by ENLIGHTEN. We
        assume no responsibility for the reliability or performance of any
        connections as described in this Section.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.5</strong> Internal Business
        Purposes Only. Unless otherwise authorized by ENLIGHTEN in this
        Agreement or expressly agreed to otherwise in writing by ENLIGHTEN, you
        may not use the Services in any manner where You act as a service bureau
        or to provide any outsourced business process services on behalf of more
        than one (1) third party (other than Affiliates) through a single
        Account. This provision is not intended to prevent or inhibit the use of
        the Services to provide business support to multiple End-Users; however,
        You agree not to license, sublicense, sell, outsource, rent, lease,
        transfer, assign, distribute, time-share or otherwise commercially
        exploit or resell the Services to any third party, other than authorized
        Agents and End-Users in furtherance of Your internal business purposes
        as expressly permitted by this Agreement, unless expressly agreed to
        otherwise in writing by ENLIGHTEN. Without limiting the foregoing, Your
        right to access and use the API is also subject to the restrictions and
        policies implemented by ENLIGHTEN from time to time with respect to the
        API as set forth in the Documentation or otherwise communicated to You
        in accordance with this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.6</strong> No Competitive Access.
        You may not access the Services for competitive purposes or if You are a
        competitor of the ENLIGHTEN.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.7</strong> Service-Specific
        Terms. The provision and use of certain Services are subject to
        ENLIGHTEN’s Service-Specific Terms.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.8</strong> Region-Specific
        Supplemental Terms. The provision and use of the Services in certain
        jurisdictions are subject to ENLIGHTEN’s Region-Specific Terms.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">2.9</strong> In-Product Cookies.
        Whenever You, Your Agents or End-Users interact with Our Services,
        ENLIGHTEN’s In-Product Cookie Policy applies.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 3. TERM, CANCELLATION AND TERMINATION</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.1</strong> Term. Unless Your
        Account and subscription to a Service are terminated in accordance with
        this Agreement or the applicable Service Order (a) Your subscription to
        a Service (including any and all Deployed Associated Services) will
        renew for a Subscription Term equivalent in length to the then-expiring
        Subscription Term; and (b) the Subscription Charges applicable to any
        subsequent Subscription Term shall be Our standard Subscription Charges
        for the applicable Service Plan and Deployed Associated Services at the
        time of such renewal.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.2</strong> Cancellation. Either
        Party may elect to terminate Your Account and subscription to a Service
        at the end of Your then-current Subscription Term by providing notice,
        in accordance with Section 18 of this Agreement, no less than thirty
        (30) days prior to the end of such Subscription Term.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.3</strong> Mutual Termination for
        Cause. A Party may terminate this Agreement for cause (a) upon written
        notice to the other Party of a material breach by the other Party if
        such breach remains uncured after thirty (90) days from the date of
        receipt of such notice; or (b) if the other Party becomes the subject of
        a petition in bankruptcy or any other proceeding relating to insolvency,
        receivership, liquidation, or assignment for the benefit of creditors.
        However, we may immediately terminate this Agreement for cause without
        notice if You violate the ENLIGHTEN User Content and Conduct
        Policy.&nbsp;
      </p>
      <p class="ql-align-justify">
        We will refund any prepaid fees covering the remainder of the
        Subscription Term as of the effective date of termination if this
        Agreement is terminated by You in accordance with this Section for
        ENLIGHTEN’s uncured material breach.&nbsp;
      </p>
      <p class="ql-align-justify">
        You must pay any unpaid fees covering the remainder of the Subscription
        Term pursuant to all applicable Service Order if We terminate this
        Agreement for Your material breach in accordance with this Section. In
        no event will Our termination for cause relieve You of Your obligation
        to pay any fees payable to Us for the period prior to termination.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.4</strong> Payment Upon
        Termination. Except for Your termination under Section 3.3, if You
        terminate Your subscription to a Service or cancel Your Account prior to
        the end of Your then-effective Subscription Term, or if We terminate or
        cancel Your Account pursuant to Section 3.3, in addition to any other
        amounts You may owe ENLIGHTEN, you must immediately pay all unpaid
        Subscription Charges associated with the remainder of such Subscription
        Term.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.5</strong> No Refunds. Except for
        Your termination rights under Section 3.3, no refunds or credits for
        Subscription Charges or other fees or payments will be provided if You
        terminate a subscription to a Service or cancel Your Account prior to
        the end of a Subscription Term.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">3.6</strong> Export of Service
        Data. Upon Your written request, We will make Service Data available to
        You for export or download as provided in the Documentation for thirty
        (30) days after the effective date of termination, expiration or
        migration of Your Account, unless We have suspended or terminated Your
        Account due to Your breach of the Agreement, in which case Your Service
        Data will be available for download until We notify You of such
        suspension or termination. Thereafter, we will have no obligation to
        maintain or provide any Service Data and We will delete Your Service
        Data in accordance with Our Service Data Deletion Policy available on
        Our Agreements and Terms Website unless prohibited by law or legal
        order.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 4. BILLING, PLAN MODIFICATIONS AND PAYMENTS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.1</strong> Payment and Billing.
        Unless otherwise expressly set forth in this Agreement, a Service Order,
        a Statement of Work, or in Supplemental Terms, or as otherwise agreed
        for Usage Charges, all Subscription Charges are due in full upon
        commencement of Your Subscription Term, or with respect to a Deployed
        Associated Service, at the time such Deployed Associated Service is
        purchased, subscribed to or otherwise deployed. You are responsible for
        providing valid and current payment information and You agree to
        promptly update your Account information, including payment information,
        with any changes that may occur (for example, a change in Your billing
        address or credit card expiration date). If You fail to pay Your
        Subscription Charges or any other charges indicated on any Service Order
        or Statement of Work, or in any Supplemental Terms, within five (5) days
        of Our notice to You that payment is delinquent, or if You do not update
        payment information upon Our request, in addition to Our other remedies,
        We may suspend access to and use of the Services by You, Agents and
        End-Users.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.2</strong> Upgrades. If You
        choose to upgrade Your Service Plan or increase the number of Agents
        authorized to access and use a Service during Your Subscription Term,
        any incremental Subscription Charges associated with such upgrade will
        be charged in accordance with the remaining Subscription Term. In any
        subsequent Subscription Term, Your Subscription Charges will reflect any
        such upgrades.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.3</strong> Downgrades. You may
        not downgrade Your Service Plan or reduce the number of Agents during
        any Subscription Term. If You desire to downgrade Your Service Plan or
        reduce the number of Agents under any Service Plan for a subsequent
        Subscription Term, you must provide ENLIGHTEN with thirty (90) days
        written notice prior to the end of Your then current Subscription Term.
        You must demote any downgraded Agents prior to the beginning of the
        subsequent Subscription Term. Downgrading Your Service Plan may cause
        loss of content, features, or capacity of the Service as available to
        You under Your Account and ENLIGHTEN is not responsible for such
        loss.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.4</strong> Taxes. Unless
        otherwise stated, our charges include Taxes. We will invoice You with
        the subscription and taxes split in two lines.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.5</strong> Payment Agent. If You
        pay by credit card or certain other payment instruments, the Services
        provide an interface for the Account owner to change credit card
        information (e.g., upon card renewal). Payments made by credit card,
        debit card or certain other payment instruments for the ENLIGHTEN
        Service are billed and processed by ENLIGHTEN’s Payment Agent. You
        hereby authorize the Payment Agent to bill Your credit card or other
        payment instrument in advance on a periodic basis in accordance with the
        terms of the Service Plan for the Services, and for periodic
        Subscription Charges applicable to Deployed Associated Services to which
        You subscribe until Your subscription to the Services terminates, and
        You further agree to pay any Subscription Charges so incurred. If
        applicable, you hereby authorize ENLIGHTEN and the Payment Agent to
        charge Your credit card or other payment instrument to establish prepaid
        credit. The Account owner will receive a receipt upon each acceptance of
        payment by the Payment Agent, or they may obtain a receipt from within
        the Services to track subscription status. To the extent the Payment
        Agent is not ENLIGHTEN, the Payment Agent is acting solely as a billing
        and processing agent for and on behalf of ENLIGHTEN and shall not be
        construed to be providing the applicable Service. The Payment Agent uses
        a third-party intermediary to manage credit card processing and this
        intermediary is not permitted to store, retain, or use Your billing
        information except to process Your credit card information for the
        Payment Agent.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.6</strong> Payment Portals. If
        You mandate ENLIGHTEN use a vendor payment portal or compliance portal
        that charges ENLIGHTEN a subscription fee or a percentage of any
        uploaded invoice as a required cost of doing business, you shall be
        invoiced by ENLIGHTEN for, and You are obligated to pay, the cost of
        this fee.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">4.7</strong> Promotional Credits.
        Any promotional credits issued hereunder will be subject to the
        ENLIGHTEN Promotional Credits Policy available on the ENLIGHTEN
        Agreements and Terms Website.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 5. CONFIDENTIAL INFORMATION</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        In connection with the Services, each Party will protect the other’s
        Confidential Information from unauthorized use, access or disclosure in
        the same manner as each Party protects its own Confidential Information,
        but with no less than reasonable care. Except as otherwise expressly
        permitted pursuant to this Agreement, each Party may use the other
        Party’s Confidential Information solely to exercise its respective
        rights and perform its respective obligations under this Agreement and
        shall disclose such Confidential Information (a) solely to the employees
        and/or non-employee service providers and contractors who have a need to
        know such Confidential Information and who are bound by terms of
        confidentiality intended to prevent the misuse of such Confidential
        Information; (b) as necessary to comply with an order or subpoena of any
        administrative agency or court of competent jurisdiction; or (c) as
        reasonably necessary to comply with any applicable law or regulation.
        Except for ENLIGHTEN’s Security Non-Disclosure Agreement (where executed
        by You), the provisions of this Section 5 shall control over any
        non-disclosure agreement by and between the Parties and any such
        non-disclosure agreement shall have no further force or effect with
        respect to the exchange of Confidential Information after the execution
        of this Agreement. This section shall not apply to any information which
        (a) was publicly known prior to the time of disclosure by the disclosing
        Party, or (b) becomes publicly known after such disclosure through no
        action or inaction of the receiving Party in violation of this
        Agreement. For clarity, any exchange of Confidential Information prior
        to the execution of this Agreement shall continue to be governed by any
        such non-disclosure agreement. Given the unique nature of Confidential
        Information, the Parties agree that any violation or threatened
        violation by a Party to this Agreement with respect to Confidential
        Information may cause irreparable injury to the other Party. Therefore,
        the Parties agree such violation or threatened violation shall entitle
        the other Party to seek injunctive or other equitable relief in addition
        to all legal remedies.&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 6. SUB-PROCESSORS AND SECURITY OF SERVICE DATA</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">6.1</strong> Sub-processors.
        ENLIGHTEN will utilize Sub-processors who will have access to or Process
        Service Data to assist in providing the Services to You. You hereby
        confirm and provide general authorization for ENLIGHTEN’s use of the
        Sub-processors listed within our Sub-processor Policy. We shall be
        responsible for the acts and omissions of members of ENLIGHTEN Personnel
        and Sub-processors to the same extent that We would be responsible if
        ENLIGHTEN was performing the services of each ENLIGHTEN Personnel or
        Sub-processor directly under the terms of this Agreement. You may sign
        up to receive notifications of any changes to our Sub-processor Policy
        within the policy webpage.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">6.2</strong> Third-Party Service
        Providers. ENLIGHTEN may use third-party service providers that are
        utilized by the ENLIGHTEN to assist in providing the Services to You,
        but do not have access to Service Data. Any third-party service
        providers utilized by the ENLIGHTEN will be subject to confidentiality
        obligations which are substantially similar to the confidentiality terms
        herein.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">6.3</strong> Safeguards. The
        ENLIGHTEN will maintain appropriate administrative, physical, and
        technical safeguards for protection of the security, confidentiality,
        and integrity.&nbsp;&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">6.4</strong> Agent Contact
        Information. ENLIGHTEN shall be the Data Controller of Agent Contact
        Information and shall Process such Agent Contact Information in
        accordance with Our Privacy Notice and ENLIGHTEN’s Controller Global
        Binding Corporate Rules. You are responsible for informing Your Agents
        of their rights set forth in Our Privacy Notice. You represent and
        warrant that You have obtained all relevant consents, permissions and
        rights and provided all relevant notices necessary under Applicable Data
        Protection Laws for ENLIGHTEN to lawfully Process Agent Contact
        Information as an independent Controller for the purposes set forth in
        ENLIGHTEN’s Privacy Notice.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">6.5</strong> Data Processing
        Agreement. The Data Processing Agreement shall be incorporated by
        reference herein into this Agreement once signed by the Parties.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 7. TEMPORARY SUSPENSION</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        We reserve the right to restrict functionalities or suspend the Services
        (or any part thereof), Your Account or Your and/or Agents’ or End-Users’
        rights to access and use the Services and remove, disable or quarantine
        any Service Data or other content if (a) We reasonably believe that You,
        Agents or End-Users have violated this Agreement; or (b) We suspect or
        detect any Malicious Software connected to Your Account or use of a
        Service by You, Agents or End-Users. This right includes the removal or
        disablement of Service Data or other content in accordance with Our
        Copyright Infringement Notice and Takedown Policy available on
        ENLIGHTEN’s Intellectual Property Website. We also reserve the right to
        immediately suspend Your Account for Your violation of the ENLIGHTEN
        User Content and Conduct Policy. Unless legally prohibited from doing so
        or where We are legally required to take immediate action, we will use
        commercially reasonable efforts to contact You via email to notify You
        when taking any of the foregoing actions. We shall not be liable to You,
        Agents, End-Users or any other third party for any modification,
        suspension or discontinuation of Your rights to access and use the
        Services. We may refer any suspected fraudulent, abusive, or illegal
        activity by You, Agents or End-Users to law enforcement authorities at
        Our sole discretion.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 8. NON-ENLIGHTEN SERVICES</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        If You decide to enable, access, or use Non-ENLIGHTEN Services, your
        access and use of such Non-ENLIGHTEN Services shall be governed solely
        by the terms and conditions of such Non-ENLIGHTEN Services. ENLIGHTEN
        does not endorse, is not responsible or liable for, and makes no
        representations as to any aspect of such Non-ENLIGHTEN Services,
        including, without limitation, their content or the manner in which they
        handle, protect, manage or Process data (including Service Data), or any
        interaction between You and the provider of such Non-ENLIGHTEN Services.
        We cannot guarantee the continued availability of such Non-ENLIGHTEN
        Service features and may cease enabling access to them without entitling
        You to any refund, credit or other compensation, if, for example and
        without limitation, the provider of a Non-ENLIGHTEN Service ceases to
        make the Non-ENLIGHTEN Service available for interoperation with the
        corresponding Service in a manner acceptable to Us. You irrevocably
        waive any claim against ENLIGHTEN with respect to such Non-ENLIGHTEN
        Services. We are not liable for any damage or loss caused or alleged to
        be caused by or in connection with Your enablement, access or use of any
        such Non-ENLIGHTEN Services, or Your reliance on the privacy practices,
        data security processes or other policies of such Non-ENLIGHTEN
        Services. You may be required to register for or log into such
        Non-ENLIGHTEN Services on their respective websites. By enabling any
        Non-ENLIGHTEN Services, You are expressly permitting ENLIGHTEN to
        disclose Your login and Service Data to the provider of the
        Non-ENLIGHTEN Service as necessary to facilitate the use or enablement
        of such Non-ENLIGHTEN Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 9. FREE TRIALS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        If You register for a free trial for any of the Services, we will make
        such Services available to You pursuant to the terms and conditions
        stated in our free trial policy.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 10. INTELLECTUAL PROPERTY RIGHTS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">10.1</strong> Intellectual Property
        Rights. Each Party shall retain all rights, title and interest in any of
        its respective Intellectual Property Rights. The rights granted to You,
        Agents and End-Users to use the Service(s) under this Agreement do not
        convey any additional rights in the Service(s) or in any Intellectual
        Property Rights of ENLIGHTEN associated therewith. Subject only to
        limited rights to access and use the Service(s) as expressly stated
        herein, all rights, title and interest in and to the Services and all
        hardware, Software and other components of or used to provide the
        Services, including all related Intellectual Property Rights, will
        remain with ENLIGHTEN and belong exclusively to ENLIGHTEN.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">10.2</strong> Feedback. The
        ENLIGHTEN Group shall have a fully paid-up, royalty-free, worldwide,
        transferable, sub-licensable (through multiple layers), assignable,
        irrevocable and perpetual license to implement, use, modify,
        commercially exploit, incorporate into the Services or otherwise use any
        suggestions, enhancement requests, recommendations or other feedback
        regarding the Services that We receive from You, Agents, End-Users, or
        other third parties acting on Your behalf. The ENLIGHTEN Group also
        reserves the right to seek intellectual property protection for any
        features, functionality or components that may be based on or that were
        initiated by suggestions, enhancement requests, recommendations or other
        feedback regarding the Services that We receive from You, Agents,
        End-Users, or other third parties acting on Your behalf.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">10.3</strong> Use of ENLIGHTEN
        Marks. You may only use the ENLIGHTEN Marks in a manner permitted by Our
        Trademark Usage Guidelines available on ENLIGHTEN’s Intellectual
        Property Website, provided You do not attempt, now or in the future, to
        claim any rights in the ENLIGHTEN Marks, dilute or degrade the
        distinctiveness of the ENLIGHTEN Marks, or use the ENLIGHTEN Marks to
        disparage or misrepresent ENLIGHTEN or Our Services. ENLIGHTEN’s ability
        to use Subscriber’s trademarks, service marks, service or trade names or
        logos will be set forth in a Service Order or by mutual agreement of the
        Parties.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">10.4</strong> Ownership of Service
        Data. Subscriber shall retain ownership rights, including all
        Intellectual Property Rights, to all Service Data Processed under the
        terms of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 11. REPRESENTATIONS, WARRANTIES AND DISCLAIMERS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">11.1</strong> Warranties. Each
        Party represents and warrants to the other that (a) this Agreement has
        been duly executed and delivered and constitutes a valid and binding
        agreement enforceable against such Party in accordance with its terms;
        (b) no authorization or approval from any third party is required in
        connection with such Party’s execution, delivery or performance of this
        Agreement; and (c) the execution, delivery and performance of the
        Agreement does not and will not violate the terms or conditions of any
        other agreement to which it is a party or by which it is otherwise
        bound.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">11.2</strong> ENLIGHTEN Warranties.
        We warrant that during an applicable Subscription Term (a) this
        Agreement and the Documentation will accurately describe the applicable
        administrative, physical, and technical safeguards for protection of the
        security, confidentiality, and integrity of Service Data; and (b) the
        Services will perform materially in accordance with the applicable
        Documentation. For any breach of a warranty in this section, your
        exclusive remedies are those described in Section 3.3 herein. The
        warranties herein do not apply to any misuse or unauthorized
        modification of the Services made by You, Your Agents or Your
        End-Users.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">11.3</strong> Disclaimers. EXCEPT
        AS SPECIFICALLY SET FORTH IN SECTION 11.2, THE SITES AND THE SERVICES,
        INCLUDING ALL SERVER AND NETWORK COMPONENTS, ARE PROVIDED ON AN “AS IS”
        AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE
        FULLEST EXTENT PERMITTED BY LAW, AND WE EXPRESSLY DISCLAIM ANY AND ALL
        WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
        ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT WE DO NOT
        WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE,
        ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE, AND NO
        INFORMATION OR ADVICE OBTAINED BY YOU FROM US OR THROUGH THE SERVICES
        SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 12. INDEMNIFICATION</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">12.1</strong> Indemnification by
        Us. We will indemnify and defend You from and against any claim brought
        by a third party against You alleging that Your use of a Service as
        permitted hereunder, infringes or misappropriates a third party’s valid
        patent, copyright, trademark or trade secret (an “IP Claim”). We shall,
        at Our expense, defend such IP Claim and pay damages finally awarded
        against You in connection therewith, including reasonable fees and
        expenses of attorneys engaged by ENLIGHTEN for such defense, provided
        that (a) You promptly notify ENLIGHTEN of the threat or notice of such
        IP Claim; (b) We will have sole, exclusive control and authority to
        select defense attorneys, defend and/or settle any such IP Claim
        (however, We shall not settle or compromise any claim that results in
        liability or admission of any liability by You without Your prior
        written consent); and (c) You fully cooperate with ENLIGHTEN in
        connection therewith. If use of a Service by You, Agents or End-Users
        has become, or, in Our opinion, is likely to become, the subject of any
        such IP Claim, We may, at Our option and expense (i) procure for You the
        right to continue using the Service(s) as set forth hereunder; (ii)
        replace or modify a Service to make it non-infringing; or (iii) if
        options (i) or (ii) are not commercially reasonable or practicable as
        determined by ENLIGHTEN, terminate Your subscription to the Service(s)
        and repay You, on a pro-rata basis, any Subscription Charges paid to
        ENLIGHTEN for the unused portion of Your Subscription Term for such
        Service(s). We will have no liability or obligation under this Section
        12.1 with respect to any IP Claim if such claim is caused in whole or in
        part by (x) compliance with designs, data, instructions or
        specifications provided by You; (y) modification of the Service(s) by
        anyone other than ENLIGHTEN or ENLIGHTEN Personnel; or (z) the
        combination, operation or use of the Service(s) with other hardware or
        software where a Service would not by itself be infringing. The
        provisions of this Section 12.1 state the sole, exclusive and entire
        liability of ENLIGHTEN to You and Your sole remedy with respect to an IP
        Claim brought by reason of access to or use of a Service by You, Agents
        or End-Users.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">12.2</strong> Indemnification by
        You. You will indemnify, defend and hold ENLIGHTEN harmless against any
        claim brought by a third party against ENLIGHTEN (a) arising from or
        related to use of a Service (and not arising solely from the Service
        itself) by You, Agents or End-Users in breach of this Agreement; or (b)
        alleging that Your use of the Service or Your Service Data infringes or
        misappropriates a third party’s valid patent, copyright, trademark or
        trade secret; provided that (i) We promptly notify You of the threat or
        notice of such claim; (ii) You will have the sole and exclusive control
        and authority to select defense attorneys, and to defend and/or settle
        any such claim (however, You shall not settle or compromise any claim
        that results in liability or admission of any liability by Us without
        Our prior written consent); and (iii) We fully cooperate with You in
        connection therewith.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 13. LIMITATION OF LIABILITY</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">13.1</strong> EXCLUSION OF DAMAGES.
        UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT,
        TORT, NEGLIGENCE OR OTHERWISE) SHALL EITHER PARTY TO THIS AGREEMENT, OR
        THEIR RESPECTIVE AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
        SERVICE PROVIDERS, SUPPLIERS OR LICENSORS, BE LIABLE TO THE OTHER PARTY
        OR ITS AFFILIATES FOR ANY LOST PROFITS, LOST SALES OR BUSINESS, LOST
        DATA (WHERE SUCH DATA IS LOST IN THE COURSE OF TRANSMISSION VIA YOUR
        SYSTEMS OR OVER THE INTERNET THROUGH NO FAULT OF ENLIGHTEN), BUSINESS
        INTERRUPTION, LOSS OF GOODWILL, COSTS OF COVER OR REPLACEMENT, OR FOR
        ANY OTHER TYPE OF INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
        CONSEQUENTIAL OR PUNITIVE LOSS OR DAMAGES, OR FOR ANY OTHER INDIRECT
        LOSS OR DAMAGES INCURRED BY THE OTHER PARTY OR ITS AFFILIATES IN
        CONNECTION WITH THIS AGREEMENT, THE SERVICES OR PROFESSIONAL SERVICES,
        REGARDLESS OF WHETHER SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
        OR COULD HAVE FORESEEN SUCH DAMAGES.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">13.2</strong> LIMITATION OF
        LIABILITY. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
        THE ENLIGHTEN GROUP’S AGGREGATE LIABILITY TO YOU, YOUR AFFILIATES, OR
        ANY THIRD PARTY ARISING OUT OF THIS AGREEMENT, THE SERVICES OR
        PROFESSIONAL SERVICES, SHALL IN NO EVENT EXCEED THE SUBSCRIPTION CHARGES
        AND/OR PROFESSIONAL SERVICES FEES PAID BY YOU DURING THE TWELVE (12)
        MONTHS PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH
        LIABILITY. YOU ACKNOWLEDGE AND AGREE THAT THE ESSENTIAL PURPOSE OF THIS
        SECTION 13.2 IS TO ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE
        PARTIES AND LIMIT POTENTIAL LIABILITY GIVEN THE SUBSCRIPTION CHARGES AND
        PROFESSIONAL SERVICES FEES, WHICH WOULD HAVE BEEN SUBSTANTIALLY HIGHER
        IF WE WERE TO ASSUME ANY FURTHER LIABILITY OTHER THAN AS SET FORTH
        HEREIN. WE HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO
        PROVIDE YOU WITH THE RIGHTS TO ACCESS AND USE THE SERVICES AND/OR THE
        PROFESSIONAL SERVICES PROVIDED FOR IN THIS AGREEMENT. THE LIMITATIONS
        SET FORTH IN SECTION 13.2 SHALL NOT APPLY TO CLAIMS OR DAMAGES RESULTING
        FROM ENLIGHTEN’S IP CLAIMS INDEMNITY OBLIGATIONS IN SECTION 12.1 OF THIS
        AGREEMENT.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">13.3</strong> LIMITATION OF
        LIABILITY IN THE AGGREGATE. THE LIMITATION OF LIABILITY PROVIDED FOR
        HEREIN APPLIES IN AGGREGATE TO ANY AND ALL CLAIMS BY SUBSCRIBER AND ITS
        AFFILIATES AND SHALL NOT BE CUMULATIVE.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">13.4</strong> Jurisdiction-specific
        exclusions. Some jurisdictions do not allow the exclusion of implied
        warranties or limitation of liability for incidental or consequential
        damages or for a party’s own fraud, willful injury to the person or
        property of another, or violation of law which means that some of the
        above limitations may not apply to You. IN THESE JURISDICTIONS, THE
        ENLIGHTEN GROUP’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT
        PERMITTED BY LAW.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">13.5</strong> Enforceable against
        ENLIGHTEN. Any claims or damages that You may have against ENLIGHTEN
        shall only be enforceable against ENLIGHTEN and not any other entity,
        nor any officers, directors, representatives or agents of ENLIGHTEN or
        any other entity.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 14. ASSIGNMENT, ENTIRE AGREEMENT AND AMENDMENT</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">14.1</strong> Assignment. You may
        not, directly or indirectly, by operation of law or otherwise, assign
        all or any part of this Agreement or Your rights under this Agreement,
        or delegate performance of Your duties under this Agreement, without Our
        written prior consent, which consent will not be unreasonably withheld.
        Notwithstanding the foregoing, subject to Section 2.6 herein, you may,
        without Our consent, assign this Agreement to an Affiliate or in
        connection with any merger or change of control of You or the sale of
        all or substantially all of Your assets, provided that (i) any such
        successor agrees to fulfill its obligations pursuant to this Agreement,
        and (ii) You and the assignee execute ENLIGHTEN’s assignment form. We
        may assign this Agreement to any member of the ENLIGHTEN Group or in
        connection with any merger or change of control of ENLIGHTEN or the
        ENLIGHTEN Group or the sale of all or substantially all of Our assets
        provided that any such successor agrees to fulfill its obligations
        pursuant to this Agreement. If requested by ENLIGHTEN, You must execute
        Our form to give effect to ENLIGHTEN’s assignment. Subject to the
        foregoing restrictions, this Agreement will be fully binding upon, inure
        to the benefit of and be enforceable by the Parties and their respective
        successors and assigns.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">14.2</strong> Entire Agreement.
        This Agreement constitutes the entire agreement and supersedes any and
        all prior agreements between You and ENLIGHTEN with regard to the
        subject matter hereof. This Agreement shall apply in lieu of the terms
        or conditions in any purchase order, request for information, request
        for proposal, or other order documentation You or any entity which You
        represent provide(s) and all such terms or conditions in such purchase
        order, request for information, request for proposal, or other order
        documentation are null and void. Except as expressly stated herein,
        there are no other agreements, representations, warranties, or
        commitments which may be relied upon by either Party with respect to the
        subject matter hereof. There are no oral promises, conditions,
        representations, understandings, interpretations or terms of any kind
        between the Parties, except as may otherwise be expressly provided
        herein. The headings used herein are for convenience only and shall not
        affect the interpretation of the terms of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">14.3</strong> Amendment. We may
        amend this Agreement from time to time, in which case the new Agreement
        will supersede prior versions. We will notify You not less than thirty
        (30) days prior to the effective date of any such amendment and your
        continued use of the Services following the effective date of any such
        amendment may be relied upon by ENLIGHTEN as Your consent to any such
        amendment. Our failure to enforce at any time any provision of this
        Agreement does not constitute a waiver of that provision or of any other
        provision of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 15. SEVERABILITY</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        If any term in this Agreement is determined to be invalid or
        unenforceable by a competent court or governing body, such term shall be
        replaced with another term consistent with the purpose and intent of
        this Agreement, and the remaining provisions of this Agreement shall
        remain in effect.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 16. EXPORT COMPLIANCE AND USE RESTRICTIONS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        The Services and other ENLIGHTEN technology, and derivatives thereof,
        may be subject to export controls and economic sanctions laws and
        regulations of the United States and other jurisdictions. Subscriber
        agrees to comply with all such laws and regulations as they relate to
        the access to and use of the Services and other ENLIGHTEN technology.
        Each Party represents that it (nor its parents or controlling
        shareholders) is not named on any U.S. government or other applicable
        restricted-party list, and Subscriber will not, and will not permit any
        Agent or End-User to: (a) access or use any Service in a U.S.-embargoed
        or U.S.-sanctioned country or region, (e.g., Cuba, Iran, North Korea,
        Syria, Crimea, or any other country or region embargoed or sanctioned
        during the Subscription Term); (b) access or use any Service if
        Subscriber, Agent, or End-User is named on any U.S. government or other
        applicable restricted-party list; (c) place any information in the
        Services that is controlled under the U.S. International Traffic in Arms
        Regulations or other similar laws; or (d) access or use any Service for
        any purpose prohibited by the United States or applicable international
        import and export laws and regulations.&nbsp;
      </p>
      <p class="ql-align-justify">
        ENLIGHTEN reserves the unconditional right to refuse to enter a
        contractual relationship with any particular company, legal entity or
        individual on the basis of export control restrictions, embargoes,
        sanctions or other considerations to the extent permitted by law.&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 17. RELATIONSHIP OF THE PARTIES</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        The Parties are independent contractors, and this Agreement does not
        create a partnership, franchise, joint venture, general agency,
        fiduciary or employment relationship between the Parties.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 18. NOTICE</strong>&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">18.1</strong> Notices to
        Subscriber. All notices provided by ENLIGHTEN to You under this
        Agreement may be delivered in writing by (a) nationally recognized
        overnight delivery service (“Courier”) or U.S. mail to the contact
        mailing address provided by You on any Service Order; or (b) electronic
        mail to the electronic mail address provided for Your Account
        owner.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">18.2</strong> Notices to ENLIGHTEN.
        All Legal Notices provided by You to ENLIGHTEN under this Agreement must
        be delivered in English and in writing by (a) Courier or U.S. mail to 30
        Wall Street, 8th Floor, suite 844, New York/New York – 10005 U.S.A.
        Attn: Legal Department; or (b) electronic mail to <a
          href="mailto:sales@be-enlighten.com.%20"
          rel="noopener noreferrer"
          target="_blank"
          style="color: windowtext"
          >sales@be-enlighten.com.&nbsp;</a
        >
        All other notices provided by You to ENLIGHTEN under this Agreement must
        be delivered in English.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">18.3</strong> All notices provided
        by either Party to the other shall be deemed to have been given
        immediately upon delivery by electronic mail; or upon the earlier of
        proof of receipt or two (2) business days after being deposited in the
        mail or with a Courier as permitted above.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 19. GOVERNING LAW</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        This Agreement shall be governed by the laws of the State of New York,
        without reference to conflict of laws principles. Any disputes under
        this Agreement shall be resolved in a court of general jurisdiction in
        New York City, New York State. You hereby expressly agree to submit to
        the exclusive personal jurisdiction of this jurisdiction for the purpose
        of resolving any dispute relating to this Agreement or relating to
        access to or use of the Services by You, Agents or End-Users.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 20. FEDERAL GOVERNMENT END USE PROVISIONS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        If You are a U.S. federal government department or agency or contracting
        on behalf of such department or agency, each of the Services is a
        “Commercial Product” as that term is defined at 48 C.F.R. §2.101,
        consisting of “Commercial Computer Software” and “Commercial Computer
        Software Documentation”, as those terms are used in 48 C.F.R. §12.212 or
        48 C.F.R. §227.7202. Consistent with 48 C.F.R. §12.212 or 48 C.F.R.
        §227.7202-1 through 227.7202-4, as applicable, the Services are licensed
        to You with only those rights as provided under the terms and conditions
        of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >SECTION 21. ETHICAL CONDUCT AND COMPLIANCE</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        Neither Party has received or been offered any illegal or improper
        bribe, kickback, payment, gift, or thing of value from any of its
        employees or agents in connection with this Agreement. Reasonable gifts
        and entertainment provided in the ordinary course of business do not
        violate the above restriction. We will abide by our Code of Conduct. If
        You learn of any violation of the above restrictions by Us, you will use
        reasonable efforts to promptly notify Us at
        sales@be-enlighten.com.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 22. SURVIVAL</strong>&nbsp;
      </p>
      <p class="ql-align-justify">
        Sections 2.1, 3.5, 3.6, 4.4, 5 – 7, 10, 13 – 19 and 23 shall survive
        termination of this Agreement with respect to use of the Services by
        You, Agents, or End-Users. Termination of this Agreement shall not limit
        a Party’s liability for obligations accrued as of or prior to
        termination or for any breach of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">&nbsp;</p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">SECTION 23. DEFINITIONS</strong
        >&nbsp;
      </p>
      <p class="ql-align-justify">
        When used in this Agreement with initial letters capitalized, these
        terms have the following meaning:&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Account”</strong> means any
        accounts or instances created by, or on behalf of, Subscriber or its
        Affiliates within the Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Additional Feature(s)”</strong
        > means additional features or functionality (e.g., Built by ENLIGHTEN
        Marketplace Applications, ENLIGHTEN Labs apps, or Early Access Programs)
        that are available or enabled through the Services, but do not form part
        of the Service. Additional Features also include third party services
        that are purchased and/or subscribed to via a Service Order and that are
        identified as being resold by ENLIGHTEN and governed by such third
        party’s alternate agreement. Additional Features are purchased or
        enabled separately and distinctly from Your Service Plan and Deployed
        Associated Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Affiliate(s)”</strong> means, with
        respect to a Party, any entity that directly or indirectly controls, is
        controlled by, or is under common control with such Party, whereby
        “control” (including, with correlative meaning, the terms “controlled
        by” and “under common control”) means the possession, directly or
        indirectly, of the power to direct, or cause the direction of the
        management and policies of such person, whether through the ownership of
        voting securities, by contract, or otherwise.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Agent(s)”</strong> means an
        individual (including those of Your Affiliates) authorized to use the
        Service(s) through Your Account as an agent, account owner and/or
        administrator, each as identified through an individual Agent
        Login.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Agent Contact Information”</strong
        > means Personal Data about Your Agents, such as their name and email
        address, that ENLIGHTEN stores separate from the Services in order to,
        among other things, communicate with Your Agents and provide customer
        support. Agent Contact Information is not considered Service Data.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Agent Login”</strong> means a
        unique username and associated password provisioned to an individual to
        permit them to access the Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Agreement”</strong> means the
        Master Subscription Agreement together with any and all Supplemental
        Terms, Service Order and other mutually executed documents.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“API”</strong> means the
        application programming interfaces developed, made available and enabled
        by ENLIGHTEN that permit Subscribers to access certain functionality
        provided by the Services, including, without limitation, the REST API
        that enables the interaction with the Services automatically through
        HTTP requests and the application development API that enables the
        integration of the Services with other web applications.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“Applicable Data Protection Law”</strong
        > means the defined term in the Data Protection Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Associated Services”</strong
        > means products, services, features and functionality designed to be
        used in conjunction with the Services that are not included in the
        Service Plan to which You subscribe. For avoidance of doubt, Additional
        Features that are expressly stated to be governed by separate
        Supplemental Terms shall not be deemed an Associated Service. Where You
        have purchased, deployed, or subscribed to an Associated Service, such
        Associated Service is referred to as a “Deployed Associated
        Service.”&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“Built by ENLIGHTEN Marketplace Applications”</strong
        > means integrations and applications created or developed by ENLIGHTEN
        or its Affiliates and made available in the ENLIGHTEN Marketplace
        (available at https://www.ENLIGHTEN.com/apps) which will be governed by
        this Agreement unless ENLIGHTEN otherwise communicates a different
        agreement to You at the time of Your deployment of or access to the
        integration or application.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“Confidential Business Information”</strong
        > means all Confidential Information that is not Service Data,
        including, without limitation, Your Agents’ Personal Data and Account
        information, which ENLIGHTEN may store in its systems separate from the
        Service and in accordance with Our security policies and
        procedures.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Confidential Information”</strong
        > means all information disclosed by one Party to the other Party which
        is in tangible form and designated as confidential or is information,
        regardless of form, which a reasonable person would understand to be
        confidential given the nature of the information and circumstances of
        disclosure, including but not limited to the pricing terms.
        Notwithstanding the foregoing, Confidential Information shall not
        include information that (a) was already known to the receiving Party at
        the time of disclosure by the disclosing Party; (b) was or is obtained
        by the receiving Party from a third party not known by the receiving
        Party to be under an obligation of confidentiality with respect to such
        information; (c) is or becomes generally available to the public other
        than by violation of this Agreement or another valid agreement between
        the Parties; or (d) was or is independently developed by the receiving
        Party without the use of the disclosing Party’s Confidential
        Information.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Data Controller”</strong> refers
        to the entity that determines the purposes and means for Processing
        Personal Data.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Documentation”</strong> means any
        written or electronic documentation, images, video, text or sounds
        specifying the functionalities or limitations of the Services or
        describing Service Plans, as applicable, provided or made available by
        ENLIGHTEN.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“End-User(s)”</strong> means any
        person or entity other than Subscriber or Agents with whom Subscriber,
        its Agents, or its other End-Users interact while using a Service.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Enterprise Services”</strong
        > means any Service not designated on ENLIGHTEN’s Innovation Services
        List available on ENLIGHTEN’s Agreements and Terms Website.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Force Majeure Event”</strong
        > means any circumstances beyond Our reasonable control, including, but
        not limited to, an act of God, act of government, flood, fire,
        earthquake, civil unrest, act of terror, strike or other labor problem
        (other than one involving Our employees), Internet service provider
        failure or delay, Non-ENLIGHTEN Services, or acts undertaken by third
        parties, including without limitation, denial of service attack.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Innovation Services”</strong
        > means any Service or feature described on ENLIGHTEN’s Innovation
        Services List available on ENLIGHTEN’s Agreements and Terms
        Website.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“Intellectual Property Rights”</strong
        > means all of a Party’s patents, inventions, copyrights, trademarks,
        domain names, trade secrets, know-how and any other intellectual
        property and/or proprietary rights.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Legal Notice”</strong> means any
        notice provided by Subscriber to ENLIGHTEN under Sections 3.3 and 12.1
        of this Agreement.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Malicious Software”</strong> means
        any viruses, malware, Trojan horses, time bombs, or any other similar
        harmful software.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Non-ENLIGHTEN Services”</strong
        > means third party products, applications, services, software,
        networks, systems, directories, websites, databases, and information
        which a Service links to, or which You may connect to or enable in
        conjunction with a Service, including, without limitation, Non-ENLIGHTEN
        Services which may be integrated directly into Your Account by You or at
        Your direction.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Payment Agent(s)”</strong> means
        ENLIGHTEN or a payment agent designated by ENLIGHTEN. For example, if
        You choose to pay for a Service with a credit card or certain other
        payment instruments in a currency other than the U.S. Dollar, ENLIGHTEN
        may designate a Payment Agent to collect.&nbsp;&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Personal Data”</strong> shall have
        the meaning as in the Applicable Data Protection Law (defined within the
        Data Processing Agreement), including but not limited to, the EU
        Regulation 2016/679 entitled “On the protection of natural persons with
        regard to the Processing of personal data and on the free movement of
        such data” (“GDPR”) and any applicable national laws implemented by
        European Economic Area (“EEA”) member countries.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Personnel”</strong> means
        employees and/or non-employee contractors of the ENLIGHTEN Group engaged
        by the ENLIGHTEN Group in connection with performance hereunder.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Planned Downtime”</strong> means
        planned downtime for upgrades and maintenance to the Services scheduled
        in advance of such upgrades and maintenance.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Privacy Notice”</strong> means
        ENLIGHTEN’s Privacy.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“Processing/to Process/Processed”</strong
        > shall have the meaning provided under Applicable Data Protection Laws
        (as defined within the Data Processing Agreement).&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Professional Services”</strong
        > means consulting or professional services (including any training,
        success and implementation services) provided by ENLIGHTEN Personnel as
        indicated on a Service Order or other written document such as an SOW.
        Professional Services may also be referred to as Consulting Services in
        the Documentation or SOW.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Service(s)”</strong> means the
        products and services that are used or ordered by You online through a
        link or via a Service Order referencing this Agreement, whether on a
        trial or paid basis, and whether Enterprise Services or Innovation
        Services, and made available online by Us, via the applicable subscriber
        login link and other web pages designated by Us, including, individually
        and collectively, the applicable Software, updates, API, Documentation,
        and all Deployed Associated Services that are provided under this
        Agreement. “Services” exclude (a) Non-ENLIGHTEN Services as that term is
        defined in this Agreement; and (b) any Additional Features or Associated
        Services that are not provided under this Agreement or Your Service
        Plan. From time to time, the names and descriptions of the Services or
        any individual Service may be changed. To the extent Subscriber is given
        access to such Service as so described by virtue of a prior Service
        Order or other prior acceptance of this Agreement, this Agreement shall
        be deemed to apply to such Service as newly named or described.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Service Data”</strong> means all
        electronic data, text, messages, communications, or other materials
        submitted to and stored within a Service by You, Agents and End-Users in
        connection with Your use of such Service, excluding Agent Contact
        Information.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Service Data Breach”</strong
        > means an unauthorized access or improper disclosure that has been
        verified to have affected Your Service Data.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Service Order”</strong> means Our
        generated service order form(s) or online ordering document, or process
        completed, executed or approved by You with respect to Your subscription
        to a Service, which may detail, among other things, the number of Agents
        authorized to use a Service under Your subscription and the Service Plan
        applicable to Your subscription.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Service Plan(s)”</strong> means
        the packaged service plan(s) and the functionality and services
        associated therewith (as detailed on the Site and in Documentation
        applicable to the Service) for the Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Site”</strong> means a website
        operated by the ENLIGHTEN Company.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Software”</strong> means software
        provided by ENLIGHTEN (either by download or access through the
        internet) that allows Agents or End-Users to use any functionality in
        connection with the applicable Service.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Sub-processor(s)”</strong> means
        any third-party data processor engaged by ENLIGHTEN, including entities
        from the ENLIGHTEN, who receives Service Data from ENLIGHTEN for
        Processing on behalf of Subscriber and in accordance with Subscriber’s
        instructions (as communicated by ENLIGHTEN).&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Subscription Charges”</strong
        > means all charges associated with Your access to and use of an
        Account.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Subscription Term”</strong> means
        the period during which You have agreed to subscribe to a Service.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Supplemental Terms”</strong> means
        the additional terms and conditions that are (a) included or
        incorporated on a Service Order via hyperlink or other reference (e.g.,
        when a Deployed Associated Service is purchased); (b) applicable to
        Professional Services when purchased by You; (c) applicable to
        Additional Features when activated by You; and (d) ENLIGHTEN’s
        Service-Specific Terms; and (e) ENLIGHTEN’s Region-Specific Terms.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Taxes”</strong> means taxes,
        levies, duties or similar governmental assessments, including
        value-added, sales, use or withholding taxes assessable by any local,
        state, provincial or foreign jurisdiction.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“Usage Charges”</strong> means
        additional Subscription Charges that are incurred by Subscriber relating
        to the use of certain features and functionality that Subscriber enables
        within the Service.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“We,” “Us”</strong> or <strong
          :class="`text-${textColor}`"
          >“Our”</strong
        > means ENLIGHTEN as defined below.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“ENLIGHTEN”</strong> means
        ENLIGHTEN, Inc., a Delaware corporation, or any of its successors or
        assignees.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Agreements and Terms Website”</strong
        > means the website.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“ENLIGHTEN Group”</strong> means
        ENLIGHTEN LAW TECH LLC a corporation together.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s In-Product Cookie Policy”</strong
        > means the policy of Enlighten.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Intellectual Property </strong
        >means the policy of Enlighten.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`">“ENLIGHTEN Marks”</strong> means
        any trademarks, service marks, service or trade names, logos or other
        designations of ENLIGHTEN, or its or their Affiliates, whether
        registered or unregistered.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Policies and Guidelines Website”</strong
        > means the website located at: www.be-enlighten.com.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Privacy and Data Protection </strong
        >means our policy located in our website.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Region-Specific Terms”</strong
        > means the Supplemental Terms applicable in certain
        jurisdictions.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Service-Specific Terms”</strong
        > means the Supplemental Terms applicable to use of different
        Services.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN’s Sub-processor Policy”</strong
        > means the policy.&nbsp;
      </p>
      <p class="ql-align-justify">
        <strong :class="`text-${textColor}`"
          >“ENLIGHTEN User Content and Conduct Policy”</strong
        > means the policy.&nbsp;
      </p>
      <p><br /></p>
    </div>
    <div class="pl-0 pb-0">
      <div class="form-group">
        <div class="form-check">
          <input
            :disabled="readTerms === false"
            class="form-check-input"
            @click="termsUse()"
            type="checkbox"
          />
          <p class="mb-0">Eu li e concordo com os termos de uso</p>
          <small :class="`text-${textColor}`" class="form-check-label"
            >Atenção! Para desbloquear a ação é necessário ler o termo</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["terms"],
  props: {
    textColor: {
      type: String,
      default: "dark",
    },
    bgColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      terms: false,
      isUserScrolling: 0,
      readTerms: false,
    };
  },
  methods: {
    handleScroll() {
      const scrollTotal = document.querySelector("#scrollTerm");
      if (scrollTotal.scrollTop > scrollTotal.scrollHeight - 500) {
        this.readTerms = true;
      }
    },
    termsUse() {
      this.terms = !this.terms;
      this.$emit("terms", this.terms);
    },
  },
};
</script>
