import { createStore } from "vuex";
import auth from "./modules/auth";
import register from "./modules/register";
import menu from "./modules/menu";
import toast from "./modules/toast";
import modals from "./modules/modals";
import user from "./modules/user";
import modules from "./modules/modules";
import newspaper from "./modules/newspaper";
import group from "./modules/group";
import groupMembers from "./modules/groupMembers";
import indicaBen from "./modules/insideApps/IndicaBen";

export default createStore({
  modules: {
    auth,
    register,
    menu,
    toast,
    modals,
    user,
    modules,
    newspaper,
    group,
    groupMembers,
    indicaBen,
  },
});
