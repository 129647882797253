import { api } from "@/services";
import router from "../../router";
import { mask } from "maska";

const axios = require("axios");

// ONE TRUE SOURCE
const state = {
  // GROUPS
  loadingStatus: "notLoading",
  // PROFILE DATA
  group: {
    name: "*****",
    corp_name: "*****",
    trading_name: "*****",
    corp_id: "*****",
    image: "*****",
    max_users: "0",
    total_users: "0",
    address: {
      street: "*****",
      city: "****",
      country_desc: "*****",
      state: "****",
      zipcode: "********",
    },
    owner: {
      info: {
        fname: "****",
        lname: "****",
        id: 0,
      },
    },
  },
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  // GROUPS
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  // PROFILE DATA
  SET_GROUP(state, payload) {
    state.group = payload;
  },
  SET_CORP_ID(state, payload) {
    state.group.corp_id = payload;
  },
  SET_GROUP_ADDRESS(state, payload) {
    state.group.address = payload;
  },
  SET_GROUP_ZIPCODE(state, payload) {
    state.group.address.zipcode = payload;
  },
  SET_GROUP_STREET(state, payload) {
    state.group.address.street = payload;
  },
  SET_GROUP_NUMBER(state, payload) {
    state.group.address.number = payload;
  },
  SET_GROUP_DISTRICT(state, payload) {
    state.group.address.district = payload;
  },
  SET_GROUP_CITY(state, payload) {
    state.group.address.city = payload;
  },
  SET_GROUP_STATE(state, payload) {
    state.group.address.state = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  // PROFILE DATA
  async fetchGroup(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("groups/group")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_GROUP", response.data);
        if (getters.getAddress(state) == null) {
          const address = {
            street: "",
            city: "",
            country_desc: "",
            zipcode: "",
            state: "",
          };
          context.commit("SET_GROUP_ADDRESS", address);
        }
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, você não pode acessar este grupo.",
          },
          { root: true }
        );
        router.push({ name: "Modulos" });
      });
  },
  updateGroupZipcode(context, event) {
    let zipcode = event.target.value.trim();

    context.commit("SET_GROUP_ZIPCODE", zipcode);
    if (zipcode.replace("-", "").length == 8) {
      context.dispatch("fetchZipcode", zipcode);
    }
  },
  updateStreet(context, event) {
    context.commit("SET_GROUP_STREET", event.target.value.trim());
  },
  updateNumber(context, event) {
    context.commit("SET_GROUP_NUMBER", event.target.value.trim());
  },
  updateDistrict(context, event) {
    context.commit("SET_GROUP_DISTRICT", event.target.value.trim());
  },
  updateCity(context, event) {
    context.commit("SET_GROUP_CITY", event.target.value.trim());
  },
  updateIdentNumber(context, event) {
    context.commit("SET_CORP_ID", event.target.value);
  },
  updateState(context, event) {
    context.commit("SET_GROUP_STATE", event.target.value.trim());
  },
  async updateAddress(context) {
    /*
      @ToDo Não fazer requisição quando o endereço já estiver preenchido
    */

    await api
      .post("group-adresses", getters.getAddress(state))
      .then(async () => {
        await api.put(`groups/${getters.getGroupId(state)}`, {
          corp_id: mask(getters.getCorpId(state), "##############"),
        });
        context.dispatch("fetchGroup");
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Informações salvas com sucesso!",
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, ocorreu um erro.",
          },
          { root: true }
        );
      });
    context.commit("SET_GROUP_STATE");
  },
  async fetchZipcode(context, zipcode) {
    await axios
      .get(`https://brasilapi.com.br/api/cep/v2/${zipcode}`)
      .then((response) => {
        const address = {
          street: response.data.street,
          city: response.data.city,
          country_desc: "Brasil",
          country_code: "1058",
          zipcode: zipcode,
          state: response.data.state,
          number: getters.getAddress(state).number,
          district: response.data.neighborhood,
        };
        context.commit("SET_GROUP_ADDRESS", address);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar este CEP.",
          },
          { root: true }
        );
      });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {
  get__managerList(state) {
    let managers = [];

    if (state.group.managers) {
      state.group.managers.forEach((manager) => {
        managers.push(manager.id);
      });
    }

    managers.push(state.group.owner.id);

    return managers;
  },
  getCorpId(state) {
    return mask(state.group.corp_id, "##.###.###/####-##");
  },
  getGroupAcronym(state) {
    return (state.group.name[0] + state.group.name[1]).toUpperCase();
  },
  getOwnerAcronym(state) {
    return (
      state.group.owner.info.fname[0] + state.group.owner.info.lname[0]
    ).toUpperCase();
  },
  getOwnerFullname(state) {
    return state.group.owner.info.fname + " " + state.group.owner.info.lname;
  },
  getIsOwner(state, getters, rootState) {
    let managersFull = state.group.managers ? state.group.managers : [];
    let managers = [];
    managersFull.forEach((manager) => {
      managers.push(manager.id);
    });
    let user = rootState["user"].user.user.id;
    if (managers.includes(user) || user == state.group.owner.id) {
      return "isOwner";
    } else {
      return "isMember";
    }
  },
  getGroupAddressLine(state) {
    return (
      state.group.address.city +
      " - " +
      state.group.address.state +
      ", " +
      state.group.address.country_desc
    );
  },
  getAddress(state) {
    return state.group.address;
  },
  getGroupId(state) {
    return state.group.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
