import { api } from "@/services";
import router from "../../router";

// ONE TRUE SOURCE
const state = {
  loadingStatus: "notLoading",
  modules: [],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_MODULES(state, payload) {
    state.modules = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async fetchModules(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("modules")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_MODULES", response.data);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar modulos disponiveis.",
          },
          { root: true }
        );
        router.push({ name: "Modulos" });
      });
  },
  openModule(context, params) {
    let endpoint = params;
    let token = context.rootState.auth.token;
    window.location = endpoint + token + "/";
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
