import { api } from "@/services";

// ONE TRUE SOURCE
const state = {
  loadingStatus: "notLoading",
  news: [],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_NEWS(state, payload) {
    state.news = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async fetchNews(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("newspapers?_sort=id:DESC")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_NEWS", response.data);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, Ocorreu um erro e não foi possivel carregar as noticias.",
          },
          { root: true }
        );
      });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
