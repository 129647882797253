<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark bg-default"
    id="sidenav-main"
  >
    <!-- MODAL GROUPS -->
    <modal
      v-model:show="modalGroupsMobile"
      gradient="primary"
      modal-classes="modal-danger modal-dialog-centered"
      style="z-index: 10000; !important"
      local="groups"
    >
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-notification">Grupo Ativo</h6>
      </template>
      <div class="pb-3 text-center">
        <h4 class="heading my-4">Escolha o grupo que deseja ativar.</h4>
        <div class="form">
          <form>
            <fieldset class="form__options">
              <p
                v-for="(group, index) in groupsAvailableMobile"
                :key="index"
                :title="group.name"
                class="form__answer"
                @click="this.selectGroupMobile(group.id)"
              >
                <input
                  type="radio"
                  :id="'group-' + group.id"
                  :value="'group-' + group.id"
                  v-model="selectedGroupMobile.ref"
                />
                <label
                  :for="'group-' + group.id"
                  class="avatar bg-gradient-primary choose"
                >
                  {{
                    group.name[0].toUpperCase() + group.name[1].toUpperCase()
                  }}
                </label>
              </p>
            </fieldset>
          </form>
          <h4 class="heading my-4">
            {{ selectedGroupMobile.name }} <br />
            {{ selectCorpIdMobile }}
          </h4>
        </div>
      </div>
      <template v-slot:footer>
        <button
          class="btn btn-info"
          disabled
          v-if="selectedGroupMobile.initial"
        >
          Salvar
        </button>
        <button
          class="btn btn-info"
          v-else
          @click.prevent.self="this.changeGroupMobile()"
        >
          Salvar
        </button>
        <button
          @click.prevent="this.toggleModalMobile('groups')"
          class="btn btn-danger ml-auto"
        >
          Cancelar
        </button>
      </template>
    </modal>
    <!-- END MODAL GROUPS -->
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <a class="navbar-brand" href="/modules">
        <img :src="logo" class="navbar-brand-img" alt="Portal do Ben" />
      </a>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a
                class="nav-link nav-link-icon"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="text-white ni ni-bell-55"></i>
              </a>
            </template>

            <a class="dropdown-item" href="#">Ver Notificações</a>
            <a class="dropdown-item" href="#">Limpar Notificações</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Configurações</a>
          </base-dropdown>
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <div class="media container">
                    <div class="row">
                      <span
                        id="user-avatar"
                        :title="fullnameMobile"
                        class="avatar avatar rounded-circle bg-gradient-primary ml-auto"
                        style="
                          box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 35%) !important;
                          position: relative;
                        "
                      >
                        {{ acronymMobile }}
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Olá, {{ userMobile.fname }}</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Meu Perfil</span>
            </router-link>
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="this.fetchGroupsMobile()"
            >
              <i class="ni ni-settings-gear-65"></i>
              <span>Trocar Grupo</span>
            </a>
            <router-link to="/notifications" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Notificações</span>
            </router-link>
            <router-link to="/help" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Suporte</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Sair</span>
            </router-link>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-12 collapse-brand text-center">
              <img :src="logoColor" />
            </div>
            <div class="col-12 collapse-close text-center">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default:
        "https://enl-public.nyc3.digitaloceanspaces.com/logos/FotoPerfil-Colorido.png",
      description: "Logo Ben Side Bar",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: "Auto Close",
    },
  },
  data() {
    return {
      logoColor:
        "https://enl-public.nyc3.digitaloceanspaces.com/logos/LogoHorizontalAzul.png",
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    ...mapActions("user", {
      fetchUserMobile: "fetchUser",
      fetchGroupsMobile: "fetchGroups",
      selectGroupMobile: "selectGroup",
      changeGroupMobile: "changeGroup",
    }),
    ...mapActions("modals", {
      toggleModalMobile: "toggleModal",
    }),
  },
  mounted() {
    this.fetchUserMobile();
  },
  computed: {
    ...mapState("modals", {
      modalGroupsMobile: (state) => state.modalGroups,
    }),
    ...mapState("user", {
      userMobile: (state) => state.user,
      selectedGroupMobile: (state) => state.selectedGroup,
      groupsAvailableMobile: (state) => state.groupsAvailable,
    }),
    ...mapGetters("user", {
      groupAcronymMobile: "getGroupAcronym",
      selectCorpIdMobile: "getSelectCorpId",
      acronymMobile: "getUserAcronym",
      fullnameMobile: "getUserFullname",
    }),
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
