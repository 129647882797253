<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar short-title="Enlighten" title="Portal do Ben">
      <template v-slot:links>
        <div v-if="menu.length > 0">
          <ul
            class="navbar-nav ml-2"
            v-for="(section, index) in menu"
            :key="index"
          >
            <h6 class="navbar-heading text-success">
              {{ section.name }}
            </h6>
            <!-- MENU LOOP -->
            <sidebar-item
              v-for="(item, idx) in section.navigations"
              :key="idx"
              :link="{
                name: item.name,
                icon: item.icon,
                path: item.path,
              }"
            />
            <!-- END MENU LOOP -->
          </ul>
        </div>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { useToast } from "vue-toastification";
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  created() {
    // ESCUTA TODOS OS TOATS E REPRODUZ
    this.$store.watch(
      (state) => state["toast"].toastMessages,
      (toastMessages) => {
        toastMessages.forEach((toast) => {
          this.toast(toast.message, {
            type: toast.type,
            timeout: 2000,
          });
        });
      }
    );
  },
  methods: {
    ...mapActions("menu", ["fetchMenu"]),
  },
  mounted() {
    // FETCH MENU
    let insideApp = "";
    // GET FIRST ROUTE PARAM
    let routePath = this.$route.path.split("/").filter(function (i) {
      return i;
    });
    // CHECK INSIDEAPP
    switch (routePath[0]) {
      case "indicaben":
        insideApp = routePath[0];
        break;
      default:
        insideApp = false;
        break;
    }
    // CALL API
    this.fetchMenu(insideApp);
  },
  computed: {
    ...mapState("menu", {
      sidebarBackground: (state) => state.sidebarBackground,
      loadingStatus: (state) => state.loadingStatus,
      menu: (state) => state.menu,
    }),
  },
};
</script>
<style lang="scss"></style>
