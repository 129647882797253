import { createRouter, createWebHistory } from "vue-router";

// LAYOUTS
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
// PAGES
import Modules from "../views/Modules.vue";
import Newspaper from "../views/Newspaper.vue";
import Profile from "../views/User/UserProfile.vue";
import Group from "../views/Groups/GroupProfile.vue";
// INSIDE MODULES
import IndReports from "../views/InsideApps/Reports.vue";
// AUTH
import Login from "../views/Auth/Login.vue";
import Logout from "../views/Auth/Logout.vue";
import PwdChange from "../views/Auth/PwdChange.vue";
import Register from "../views/Auth/Register.vue";
import Recovery from "../views/Auth/Recovery.vue";
import Password from "../views/Auth/Password.vue";

const routes = [
  {
    path: "/",
    redirect: "/modules",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/change",
        name: "change",
        components: { default: PwdChange },
      },
      {
        path: "/modules",
        name: "Modulos",
        components: { default: Modules },
      },
      {
        path: "/newspaper",
        name: "Noticias",
        components: { default: Newspaper },
      },
      {
        path: "/group",
        name: "Meu Grupo",
        components: { default: Group },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
      {
        path: "/logout",
        name: "logout",
        components: { default: Logout },
      },
    ],
  },
  {
    path: "/indicaben",
    redirect: "/indicaben/reports",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/indicaben/reports",
        name: "Reports",
        components: { default: IndReports },
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/login",
    component: AuthLayout,
    meta: {
      requiresVisitor: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register/:token",
        name: "register",
        components: { default: Register },
      },
      {
        path: "/recovery",
        name: "recovery",
        components: { default: Recovery },
      },
      {
        path: "/password/:token",
        name: "password",
        components: { default: Password },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/modules" },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

export default router;
