import { api } from "@/services";
import router from "../../../router";

// ONE TRUE SOURCE
const state = {
  loadingStatus: "notLoading",
  reports: [],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_LOADING_STATUS(state, payload) {
    state.loadingStatus = payload;
  },
  SET_REPORTS(state, payload) {
    state.reports = payload;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async fetchReports(context) {
    context.commit("SET_LOADING_STATUS", "loading");
    await api
      .get("ind-reports")
      .then((response) => {
        context.commit("SET_LOADING_STATUS", "notLoading");
        context.commit("SET_REPORTS", response.data);
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não conseguimos encontrar modulos disponiveis.",
          },
          { root: true }
        );
        router.push({ name: "Relatorios" });
      });
  },
  toggleFullscreen() {
    let elem = document.querySelector("iframe");

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
