<template>
  <div class="card shadow-sm" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3
            class="mb-0 text-left"
            :class="type === 'dark' ? 'text-white' : ''"
          >
            <button
              class="btn p-2"
              title="Exportar membros do grupo para excel."
              style="cursor: pointer"
              @click.prevent="this.exportMembers2Excel()"
            >
              {{ members.length }} {{ title }}
              <i class="fa fa-file-download"></i>
            </button>
          </h3>
        </div>
        <!-- ADD MEMBER BTN -->
        <div class="col-4 text-right">
          <button
            @click.prevent="this.toggleModal('addMember')"
            class="btn btn-sm btn-primary bg-gradient-primary"
          >
            <i class="fa fa-plus"></i> Convidar membros
          </button>
        </div>
      </div>
    </div>
    <!-- SEARCH -->
    <div class="border-0">
      <el-input
        placeholder="Pesquise por Nome, Empresa ou Email"
        v-model="filters.search"
        @input="updateSearch"
        class="input-with-select"
      >
        <template #prepend>
          <el-select v-model="select" placeholder="Select">
            <el-option label="Todos" value=""></el-option>
            <el-option label="Nome" value="1"></el-option>
            <el-option label="Empresa" value="2"></el-option>
            <el-option label="Email" value="3"></el-option>
          </el-select>
        </template>
        <template #append>
          <el-button icon="el-icon-search"></el-button>
        </template>
      </el-input>
    </div>
    <!-- SEARCH END -->

    <div class="table-responsive">
      <base-table
        class="table table-flush table-hover"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="members"
      >
        <template v-slot:columns>
          <th class="text-left py-4">Membro</th>
          <th
            class="text-center"
            v-if="!filterRole"
            @click="filterRole = !filterRole"
          >
            <span class="pt-1">Licenças / Cargo</span>
            <span class="pulse">
              <i class="fa fa-question text-white"></i>
            </span>
          </th>
          <th v-else @click="filterRole = !filterRole" class="align-middle">
            <div class="col-12 mr-7">
              <i
                class="fa fa-undo-alt border bg-gradient-primary text-white"
                style="
                  padding: 9px 10px;
                  border-top-left-radius: 50%;
                  border-bottom-left-radius: 50%;
                  margin-right: -8px;
                  cursor: pointer;
                "
              ></i>
              <!-- FILTER USER ROLE -->
              <el-select
                v-model="filters.role"
                @change="updateFilterRole"
                popper-class="text-white "
                placeholder="Filtre por cargos"
                size="small"
                :multiple="true"
                :multiple-limit="1"
              >
                <el-option-group
                  v-for="group in filterLicenses"
                  :key="group.label"
                  :label="group.label"
                  class="text-white"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
              <!-- FILTER USER ROLE -->
            </div>
          </th>
          <th class="text-center">Ações</th>
        </template>

        <template v-slot:default="row">
          <!-- TD USER/IMAGE -->
          <th scope="row">
            <div class="media align-items-center text-left">
              <span class="avatar rounded-circle bg-gradient-primary mr-3">
                <img
                  v-if="row.item.img"
                  alt="Image placeholder"
                  :src="row.item.img"
                />
                <span v-else> {{ row.item.acron }} </span>
              </span>
              <div class="media-body">
                <div class="name mb-0 text-sm">{{ row.item.name }}</div>
                <div>
                  <span class="text-primary">{{ row.item.email }}</span>
                </div>
                <div v-if="row.item.office" class="name mb-0 text-sm">
                  <div>
                    <el-tooltip
                      effect="dark"
                      placement="bottom-start"
                      style="cursor: help"
                    >
                      <template #content>
                        <strong>Razão Social</strong>:
                        {{ row.item.office.corp_name }} <br />
                        <strong>CPF/CNPJ</strong>:
                        {{ row.item.office.corp_id }}
                      </template>
                      <small>{{ row.item.office.ref_name }}</small>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </th>
          <td>
            <div v-if="row.item.licenses.length > 0" class="avatar-group">
              <span
                class="mx-0"
                v-for="(license, index) in row.item.licenses"
                :key="index"
                @click.prevent="
                  this.loadLicenseModal({ mbIndex: row.ref, lcIndex: index })
                "
              >
                <el-tooltip
                  effect="light"
                  placement="bottom-start"
                  style="cursor: help"
                >
                  <template #content>
                    <strong class="text-dark">Modulo</strong>:
                    {{ license.module }} <br />
                    <strong class="text-dark">Cargo</strong>:
                    {{ license.role }} <br />
                    <br />
                    <small class="text-gray"
                      >Clique no icone para mais opções...</small
                    >
                  </template>
                  <button class="avatar rounded-circle bg-gradient-warning">
                    <i :class="license.icon"></i>
                  </button>
                </el-tooltip>
              </span>
            </div>
            <div v-else>---</div>
          </td>
          <!-- TD ACTIONS -->
          <td>
            <div class="btn-toolbar" role="toolbar" aria-label="Ações Membro">
              <div
                class="btn-group btn-group-sm mx-auto"
                role="group"
                aria-label="Grupo de Ações"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  title="Gerenciar Licenças"
                  @click.prevent="this.loadAddLicenseModal(row.ref)"
                >
                  <i class="fa fa-check-circle"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  title="Gerenciamento de Entidade"
                  @click.prevent="openAddOfficeModal(row)"
                >
                  <i class="fa fa-building"></i>
                </button>
                <el-popconfirm
                  confirm-button-text="Sim"
                  cancel-button-text="Não"
                  :title="`Tem certeza que deseja exluir o usuário: ${row.item.name} do grupo ${group.name}?`"
                  @confirm="
                    sendRmvMemberGroup({
                      group: group.id,
                      member: row.item.id,
                    })
                  "
                >
                  <template #reference>
                    <button
                      v-if="loadingStatus != 'notLoading'"
                      class="btn btn-primary"
                      title="Removendo..."
                    >
                      <i class="el-icon-loading"></i>
                    </button>
                    <button
                      v-else
                      :disabled="get__managerList.includes(row.item.id)"
                      class="btn btn-primary"
                      title="Remover do Grupo"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-center"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    ></div>

    <!-- MODAL ADD MEMBER -->
    <modal
      v-model:show="modalAddMember"
      gradient="default"
      modal-classes="modal-danger modal-dialog-centered modal-lg"
      local="addMember"
    >
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-notification">
          Convidar membro ao grupo
        </h6>
      </template>
      <form role="form" @submit.prevent="addEmail(addMemberForm.emails)">
        <!-- EMAIL INPUT -->
        <div class="row">
          <div class="col-12">
            <div class="font-weight-bold mb-3">1. Insira um email</div>
          </div>
        </div>
        <div class="form-group input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text text-primary"
              ><i class="fa fa-at"></i
            ></span>
          </div>
          <input
            v-maska="{
              mask: 'Z*',
              tokens: { Z: { pattern: /^[a-zA-Z0-9! @_.;&-]*$/ } },
            }"
            type="text"
            v-model="addMemberForm.emails"
            placeholder="Digite um email..."
            class="form-control"
            aria-describedby="addon-right addon-left"
            @keyup.enter="addEmail(addMemberForm.emails)"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary text-white btn-add-mail"
              :disabled="addMemberForm.emails.length < 3"
              type="button"
              @click.prevent="addEmail(addMemberForm.emails)"
            >
              +
            </button>
          </div>
        </div>
        <el-checkbox
          v-model="fillEntity"
          label="Preencher Entidade"
          text-color="#FFFFFF"
          size="large"
          class="color: #FFFFFF; font-weight: bold"
        />
        <!-- EMAIL INPUT END -->
        <!-- EMAIL ERROR MESSAGE -->
        <div
          class="font-weight-bold text-left pl-0 mb-4"
          style="font-size: smaller"
        >
          <div
            v-for="(message, index) in addMemberForm.emails_messages"
            :key="index"
            class=""
          >
            <i class="fa fa-exclamation-circle"></i>&nbsp;
            <span style="text-decoration: line-through"> {{ message }}</span>
          </div>
        </div>
        <!-- EMAIL ERROR MESSAGE END -->
        <!-- EMAIL INVITE LIST -->
        <span
          v-if="addMembersInvite.emails.length > 0"
          class="font-weight-bold"
        >
          Enviar convite para &nbsp;
          <span
            class="badge badge-warning text-white p-1 mt--1 ml--1 text-lowercase"
            style="position: absolute"
            >x{{ addMembersInvite.emails.length }}</span
          >
        </span>
        <div class="d-flex justify-content-center row mt-2">
          <span
            @click="rmvEmail(index)"
            title="Editar/Remover"
            v-for="(mail, index) in addMembersInvite.emails"
            :key="index"
            class="badge badge-pill badge-warning text-white m-1 badge-mail"
            >{{ mail }}</span
          >
        </div>
        <!-- EMAIL INVITE LIST END  -->
        <!-- LICENSES AVAILABLE -->
        <div v-if="addMembersInvite.emails.length > 0">
          <hr />
          <div class="row">
            <div class="col-12">
              <div class="font-weight-bold">
                2. Escolha o modulo e defina cargo
              </div>
            </div>
          </div>
          <div v-if="licensesAvailable.length > 0" class="container mb-4">
            <!-- MODULOS DISPONIVEIS -->
            <div class="d-flex justify-content-center flex-wrap">
              <div
                class="text-center my-2 mx-3"
                v-for="(license, index) in licensesAvailable"
                :key="index"
                @click="updateSelectedModule(index)"
              >
                <span
                  class="avatar mb-2"
                  :class="{
                    'bg-gradient-primary':
                      license.module_id == getSelectedModule.module_id,
                    'bg-gradient-warning':
                      license.module_id != getSelectedModule.module_id,
                  }"
                  ><i class="" :class="license.icon"></i
                ></span>
                <div>
                  <strong>{{ license.module }}</strong>
                </div>
              </div>
            </div>
            <!-- ROLES DO MODULO -->
            <div
              class="col-12 text-center"
              v-if="getSelectedModule.roles.length > 0"
            >
              <div
                class="text-center m-3 mx-4"
                v-for="(role, index_role) in getSelectedModule.roles"
                :key="index_role"
              >
                <button
                  class="btn btn-outline-primary px-4 rounded-pill role-link"
                  :class="{
                    'bg-gradient-primary': getAddMemberSelectedRole == role.id,
                  }"
                  @click.prevent="
                    useLicenseAvailable({
                      module: indexLicenseSelected + 1,
                      role: index_role + 1,
                    })
                  "
                >
                  <strong>{{ role.name }}</strong>
                </button>
              </div>
            </div>
            <hr />
            <div class="mt-4" v-if="addMemberValidation">
              Garantir
              <strong>{{ addMembersInvite.emails.length }}</strong>
              <span v-if="addMembersInvite.emails.length == 1"> acesso</span>
              <span v-else> acessos</span> ao modulo
              <strong>{{ addMemberPayload.module }}</strong>
              com nivel de acesso ao cargo
              <strong>{{ addMemberPayload.role.name }}</strong
              >.
            </div>
          </div>
        </div>
        <!-- LICENSES AVAILABLE END -->
        <!-- ENTITY DATA -->
        <div v-if="addMembersInvite.emails.length > 0 && fillEntity">
          <hr />
          <div class="row">
            <div class="col-12">
              <div class="font-weight-bold">3. Dados de Entidade</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <el-collapse
              style="
                background-color: transparent !important;
                border: none !important;
              "
            >
              <el-collapse-item title="Dados Principais" name="1">
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.razao_social"
                      placeholder="Razão Social"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.nome_fantasia"
                      placeholder="Nome Fantasia"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      v-maska="'##.###.###/####-##'"
                      type="text"
                      v-model="addMemberForm.cnpj"
                      placeholder="CNPJ"
                      class="form-control"
                      v-if="addMemberForm.tipo_fornecedor == 'Pessoa Jurídica'"
                    />
                    <input
                      v-maska="'###.###.###-##'"
                      type="text"
                      v-model="addMemberForm.cnpj"
                      placeholder="CPF"
                      class="form-control"
                      aria-describedby="addon-right"
                      v-if="addMemberForm.tipo_fornecedor == 'Pessoa Física'"
                    />
                  </div>
                  <div
                    class="col-6"
                    v-if="addMemberForm.tipo_fornecedor == 'Pessoa Jurídica'"
                  >
                    <input
                      type="text"
                      v-model="addMemberForm.cnae"
                      placeholder="CNAE"
                      class="form-control"
                    />
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Dados Complementares" name="2">
                <div class="row mt-4">
                  <div class="col-6">
                    <el-select
                      v-model="addMemberForm.tipo_fornecedor"
                      style="width: 100%"
                      placeholder="Tipo de Fornecedor"
                      size="large"
                      @change="addMemberForm.cnpj = ''"
                    >
                      <el-option
                        v-for="item in opt_fornecedor"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </div>
                  <div class="col-6">
                    <el-select
                      v-model="addMemberForm.tipo_tributacao"
                      style="width: 100%"
                      placeholder="Tipo de Tributação"
                      size="large"
                    >
                      <el-option
                        v-for="item in opt_tributacao"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <el-select
                      v-model="addMemberForm.servico_prestado"
                      style="width: 100%"
                      placeholder="Serviço Prestado"
                      size="large"
                    >
                      <el-option
                        v-for="item in opt_servico"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.codigo_servico"
                      placeholder="Código do Serviço"
                      class="form-control"
                    />
                  </div>
                </div>
                <div
                  class="row mt-4"
                  v-if="addMemberForm.tipo_fornecedor == 'Pessoa Jurídica'"
                >
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.inscricao_estadual"
                      placeholder="Inscrição Estadual"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.inscricao_municipal"
                      placeholder="Inscrição Municipal"
                      class="form-control"
                    />
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Informações de Contato" name="3">
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      v-maska="'+## (##) #####-####'"
                      type="text"
                      v-model="addMemberForm.telefone1"
                      placeholder="Telefone 1"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      v-maska="'+## (##) #####-####'"
                      type="text"
                      v-model="addMemberForm.telefone2"
                      placeholder="Telefone 2"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.email1"
                      placeholder="E-mail 1"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.email2"
                      placeholder="E-mail 2"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.nome_contato1"
                      placeholder="Nome para Contato 1"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.nome_contato2"
                      placeholder="Nome para Contato 2"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.endereco"
                      placeholder="Endereço"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.complemento"
                      placeholder="Complemento"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.bairro"
                      placeholder="Bairro"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.cidade"
                      placeholder="Cidade"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.estado"
                      placeholder="Estado"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.pais"
                      placeholder="País"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      v-maska="'#####-###'"
                      type="text"
                      v-model="addMemberForm.cep"
                      placeholder="CEP"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6"></div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Dados Bancários" name="4">
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.nome_banco"
                      placeholder="Nome do Banco"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.agencia"
                      placeholder="Nº da Agência"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.titularidade"
                      placeholder="Titularidade"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.numero_banco"
                      placeholder="Nº do Banco"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="addMemberForm.conta_corrente"
                      placeholder="Conta Corrente"
                      class="form-control"
                    />
                  </div>
                  <div class="col-6"></div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <!-- LICENSES AVAILABLE END -->
      </form>
      <template v-slot:footer>
        <el-popconfirm
          v-if="addMemberValidation"
          confirm-button-text="Sim"
          cancel-button-text="Não"
          :title="`Tem certeza que deseja garantir ${addMembersInvite.emails.length} acesso ao modulo
          ${addMemberPayload.module} com nível de acesso ao cargo ${addMemberPayload.role.name}?`"
          @confirm="sendMemberInvite()"
        >
          <template #reference>
            <button
              class="btn btn-primary"
              :disabled="addMemberValidation == false"
            >
              Enviar
            </button>
          </template>
        </el-popconfirm>
        <button
          @click.prevent="this.toggleModal('addMember')"
          class="btn btn-danger ml-auto"
        >
          Cancelar
        </button>
      </template>
    </modal>
    <!-- END MODAL ADD MEMBER  -->
    <!-- MODAL ADD OFFICE -->
    <modal
      v-model:show="modalAddOffice"
      gradient="default"
      modal-classes="modal-danger modal-dialog-centered modal-lg"
      local="addOffice"
    >
      <div class="text-center">
        <h2 class="modal-title text-center" style="display: inline" id="modal-title-notification">
          Gerenciamento de Entidade
        </h2>
        <button
          type="button"
          class="close"
          @click="closeAddOfficeModal"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span>×</span>
        </button>
      </div>
      <form role="form">
        <div class="form-group mt-4">
          <el-collapse style="border: none !important">
            <el-collapse-item title="Dados Principais" name="1">
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.corp_name"
                    placeholder="Razão Social"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.nome_fantasia"
                    placeholder="Nome Fantasia"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    v-maska="'##.###.###/####-##'"
                    type="text"
                    v-model="updateOffice.corp_id"
                    placeholder="CNPJ"
                    class="form-control"
                    aria-describedby="addon-right"
                    v-if="updateOffice.tipo_fornecedor == 'Pessoa Jurídica'"
                  />
                  <input
                    v-maska="'###.###.###-##'"
                    type="text"
                    v-model="updateOffice.corp_id"
                    placeholder="CPF"
                    class="form-control"
                    aria-describedby="addon-right"
                    @input="updateCorpId"
                    v-if="updateOffice.tipo_fornecedor == 'Pessoa Física'"
                  />
                </div>
                <div
                  class="col-6"
                  v-if="updateOffice.tipo_fornecedor == 'Pessoa Jurídica'"
                >
                  <input
                    type="text"
                    v-model="updateOffice.cnae"
                    placeholder="CNAE"
                    class="form-control"
                  />
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Dados Complementares" name="2">
              <div class="row mt-4">
                <div class="col-6">
                  <el-select
                    v-model="updateOffice.tipo_fornecedor"
                    style="width: 100%"
                    placeholder="Tipo de Fornecedor"
                    size="large"
                    @change="updateOffice.corp_id = ''"
                  >
                    <el-option
                      v-for="item in opt_fornecedor"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
                <div class="col-6">
                  <el-select
                    v-model="updateOffice.tipo_tributacao"
                    style="width: 100%"
                    placeholder="Tipo de Tributação"
                    size="large"
                  >
                    <el-option
                      v-for="item in opt_tributacao"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <el-select
                    v-model="updateOffice.servico_prestado"
                    style="width: 100%"
                    placeholder="Serviço Prestado"
                    size="large"
                  >
                    <el-option
                      v-for="item in opt_servico"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.codigo_servico"
                    placeholder="Código do Serviço"
                    class="form-control"
                  />
                </div>
              </div>
              <div
                class="row mt-4"
                v-if="updateOffice.tipo_fornecedor == 'Pessoa Jurídica'"
              >
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.inscricao_estadual"
                    placeholder="Inscrição Estadual"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.inscricao_municipal"
                    placeholder="Inscrição Municipal"
                    class="form-control"
                  />
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Informações de Contato" name="3">
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    v-maska="'+## (##) #####-####'"
                    type="text"
                    v-model="updateOffice.telefone1"
                    placeholder="Telefone 1"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    v-maska="'+## (##) #####-####'"
                    type="text"
                    v-model="updateOffice.telefone2"
                    placeholder="Telefone 2"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.email1"
                    placeholder="E-mail 1"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.email2"
                    placeholder="E-mail 2"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.nome_contato1"
                    placeholder="Nome para Contato 1"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.nome_contato2"
                    placeholder="Nome para Contato 2"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.endereco"
                    placeholder="Endereço"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.complemento"
                    placeholder="Complemento"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.bairro"
                    placeholder="Bairro"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.cidade"
                    placeholder="Cidade"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.estado"
                    placeholder="Estado"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.pais"
                    placeholder="País"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    v-maska="'#####-###'"
                    type="text"
                    v-model="updateOffice.cep"
                    placeholder="CEP"
                    class="form-control"
                  />
                </div>
                <div class="col-6"></div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Dados Bancários" name="4">
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.nome_banco"
                    placeholder="Nome do Banco"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.agencia"
                    placeholder="Nº da Agência"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.titularidade"
                    placeholder="Titularidade"
                    class="form-control"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.numero_banco"
                    placeholder="Nº do Banco"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <input
                    type="text"
                    v-model="updateOffice.conta_corrente"
                    placeholder="Conta Corrente"
                    class="form-control"
                  />
                </div>
                <div class="col-6"></div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </form>
      <template v-slot:footer>
        <button
          class="btn btn-success"
          :disabled="loadingStatus != 'notLoading'"
          @click.prevent.self="sendUpdateOffice(updateOffice)"
        >
          Atualizar
        </button>
        <button
          @click.prevent="this.toggleModal('addOffice')"
          class="btn btn-danger ml-auto"
        >
          Cancelar
        </button>
      </template>
    </modal>
    <!-- END MODAL ADD OFFICE  -->
    <!-- MODAL LICENSE MEMBER -->
    <modal
      v-model:show="modalLicenseMember"
      gradient="warning"
      modal-classes="modal-danger modal-dialog-centered modal-md"
      local="licenseMember"
      type="mini"
    >
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-notification">
          {{ licensesModal.user }}
        </h6>
      </template>
      <div class="pb-3 text-center">
        <i
          class="avatar avatar-md bg-gradient-default mb-2"
          :class="licensesModal.icon"
        ></i>
        <p>
          <strong>{{ licensesModal.user }}</strong> tem o cargo
          <el-tooltip
            placement="top-start"
            :content="licensesModal.role_desc"
            effect="light"
          >
            <strong>{{ licensesModal.role }}</strong>
          </el-tooltip>
          em
          <strong>{{ licensesModal.module }}.</strong>
        </p>
      </div>
      <template v-slot:footer>
        <button
          class="btn btn-danger"
          :disabled="addLicenseValidation == false"
          @click.prevent.self="
            sendRmvLicense({
              user: licensesModal.user_id,
              module: licensesModal.module_id,
            })
          "
        >
          Remover Licença
        </button>
        <button
          @click.prevent="this.toggleModal('licenseMember')"
          class="btn btn-success ml-auto"
        >
          Fechar
        </button>
      </template>
    </modal>
    <!-- END MODAL LICENSE MEMBER  -->
    <!-- MODAL ADD LICENSE -->
    <modal
      v-model:show="modalAddLicenseMember"
      gradient="success"
      modal-classes="modal-danger modal-dialog-centered modal-lg"
      local="addLicenseMember"
      type="mini"
    >
      <template v-slot:header>
        <h5 class="modal-title" id="modal-title-notification">
          Adicionar Licenças para {{ addLicensesModal.user.name }}
        </h5>
      </template>
      <!-- BODY MODAL -->
      <div v-if="getMemberLicensesAvailable.length > 0">
        <div class="text-center">
          <h4 class="text-white">Licenças disponiveis para atribuição.</h4>
        </div>
        <!-- MODULOS DISPONIVEIS -->
        <div class="d-flex justify-content-center flex-wrap">
          <div
            class="text-center my-2 mx-3"
            v-for="(lic, indexL) in getMemberLicensesAvailable"
            :key="lic.module_id"
            @click="selectModuleAddLicense(indexL)"
          >
            <span
              class="avatar mb-2"
              :class="{
                'bg-gradient-primary': addLicensesModal.module == indexL,
                'bg-gradient-warning': addLicensesModal.module != indexL,
              }"
              ><i class="" :class="lic.icon"></i
            ></span>

            <div>
              <strong>{{ lic.module }}</strong>
            </div>
          </div>
        </div>
        <!-- ROLES DO MODULO -->
        <div
          class="col-12 text-center"
          v-if="getMemberLicensesAvailable.length > 0"
        >
          <div class="text-center mt-3">
            <h4 class="text-white">
              Cargos disponiveis em
              {{ getMemberLicensesAvailable[addLicensesModal.module].module }}.
            </h4>
          </div>
          <div
            class="text-center m-3 mx-4"
            v-for="(role, indexR) in getMemberLicensesAvailable[
              addLicensesModal.module
            ].roles"
            :key="indexR"
          >
            <button
              class="btn btn-outline-primary px-4 rounded-pill role-link"
              :class="{
                'bg-gradient-primary': addLicensesModal.role == indexR,
              }"
              @click.prevent="selectModuleAddRole(indexR)"
            >
              <strong>{{ role.name }}</strong>
            </button>
          </div>
        </div>
        <hr />
        <!-- CONFIRM STRING -->
        <p class="text-center">
          Atribuir <strong>01</strong> Licença
          <strong>{{
            getMemberLicensesAvailable[addLicensesModal.module].module
          }}</strong>
          para <strong>{{ addLicensesModal.user.name }}</strong>
          com o Cargo de
          <strong>{{
            getMemberLicensesAvailable[addLicensesModal.module]
              ? getMemberLicensesAvailable[addLicensesModal.module].roles[
                  addLicensesModal.role
                ].name
              : "----"
          }}</strong>
        </p>
      </div>
      <div v-else>
        <p class="text-white">
          <strong>Este usuário já possui todas as licenças disponiveis.</strong>
        </p>
      </div>
      <!-- END BODY MODAL -->
      <template v-slot:footer>
        <button
          class="btn btn-primary"
          :disabled="addLicenseValidation == false"
          @click.prevent.self="sendAddLicense()"
        >
          Enviar
        </button>
        <button
          @click.prevent="this.toggleModal('addLicenseMember')"
          class="btn btn-danger ml-auto"
        >
          Cancelar
        </button>
      </template>
    </modal>
    <!-- END MODAL ADD LICENSE  -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "members-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      filterRole: false,
      value: "",
      licenseSelect: "",
      radio: "",
      select: "",
      fillEntity: false,
      updateOffice: {},
      opt_fornecedor: ["Pessoa Física", "Pessoa Jurídica"],
      opt_tributacao: ["Lucro Simples", "Lucro Presumido", "Lucro Real", "MEI"],
      opt_servico: [
        "Embalagens de Produto",
        "Insumos",
        "Matéria Prima",
        "Material Aera Complementar",
        "Material Caldeiraria",
        "Material Elétrica",
        "Material Escritório",
        "Material informática",
        "Material Instrumentação",
        "Material Laboratório",
        "Material Mecânica",
      ],
    };
  },
  methods: {
    ...mapActions("groupMembers", {
      sendRmvMemberGroup: "sendRmvMemberGroup",
      updateSelectedModule: "updateSelectedModule",
      fetchMembers: "fetchMembers",
      loadLicenseModal: "loadLicenseModal",
      loadAddLicenseModal: "loadAddLicenseModal",
      loadAddOfficeModal: "loadAddOfficeModal",
      closeAddOfficeModal: "closeAddOfficeModal",
      addEmail: "addEmail",
      rmvEmail: "rmvEmail",
      useLicenseAvailable: "useLicenseAvailable",
      rmvLicenseAvailable: "rmvLicenseAvailable",
      sendMemberInvite: "sendMemberInvite",
      sendAddLicense: "sendAddLicense",
      sendRmvLicense: "sendRmvLicense",
      selectModuleAddLicense: "selectModuleAddLicense",
      selectModuleAddRole: "selectModuleAddRole",
      updateSearch: "updateSearch",
      updateFilterRole: "updateFilterRole",
      updateOfficeModalCORP_ID: "updateOfficeModalCORP_ID",
      updateOfficeModalCORP_NAME: "updateOfficeModalCORP_NAME",
      updateOfficeModalREF_NAME: "updateOfficeModalREF_NAME",
      exportMembers2Excel: "exportMembers2Excel",
      sendUpdateOffice: "sendUpdateOffice",
    }),
    ...mapActions("modals", {
      toggleModal: "toggleModal",
    }),
    async openAddOfficeModal(row) {
      await this.loadAddOfficeModal(row.ref);
      this.updateOffice = this.officeModal ? this.officeModal : {};
    },
  },
  computed: {
    ...mapState("group", {
      group: (state) => state.group,
    }),
    ...mapState("groupMembers", {
      indexLicenseSelected: (state) => state.indexLicenseSelected,
      loadingStatus: (state) => state.loadingStatus,
      licensesModal: (state) => state.licensesModal,
      addLicensesModal: (state) => state.addLicensesModal,
      addMemberForm: (state) => state.addMemberForm,
      addMembersInvite: (state) => state.addMembersInvite,
      licensesAvailable: (state) => state.licensesAvailable,
      filters: (state) => state.filters,
    }),
    ...mapGetters("groupMembers", {
      getAddMemberSelectedRole: "getAddMemberSelectedRole",
      getSelectedModule: "getSelectedModule",
      addMemberValidation: "getAddMemberValidation",
      addLicenseValidation: "getAddLicenseValidation",
      addMemberPayload: "getAddMemberPayload",
      getMemberLicensesAvailable: "getMemberLicensesAvailable",
      members: "getMembers",
      filterLicenses: "getFilterLicenses",
      officeModal: "getOfficeModal",
    }),
    ...mapGetters("group", {
      get__managerList: "get__managerList",
    }),
    ...mapState("modals", {
      modalAddMember: (state) => state.modalAddMember,
      modalAddOffice: (state) => state.modalAddOffice,
      modalLicenseMember: (state) => state.modalLicenseMember,
      modalAddLicenseMember: (state) => state.modalAddLicenseMember,
    }),
  },
  mounted() {
    this.fetchMembers();
  },
};
</script>
<style>
.role-link {
  cursor: pointer;
  width: 200px !important;
}
.badge-mail:hover {
  background-color: #f5365c;
  color: white;
  cursor: pointer;
}
.btn-add-mail:hover {
  background-color: #2dce89;
}
/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  text-align: start;
  overflow: hidden;
}
select {
  flex: 1;
  cursor: pointer;
  width: 100%;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 12px;
  right: 0;
  padding: 0 1em;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #6070e4;
}
.pulse {
  padding: 3px 4px;
  position: relative;
  margin-left: 4px;
  font-size: 0.6em;
  border-radius: 5px;
  background: #646fe4;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(116, 85, 255, 0.329);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(87, 44, 204, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(92, 44, 204, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 73, 204, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(87, 44, 204, 0.4);
    box-shadow: 0 0 0 0 rgba(87, 44, 204, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(92, 44, 204, 0);
    box-shadow: 0 0 0 10px rgba(92, 44, 204, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(92, 44, 204, 0);
    box-shadow: 0 0 0 0 rgba(92, 44, 204, 0);
  }
}
.el-collapse-item__wrap {
  background-color: transparent !important;
  border: none !important;
}
.el-collapse-item__content {
  background-color: transparent !important;
}
.el-collapse-item__header {
  background-color: transparent !important;
  color: white !important;
}
.el-checkbox__label {
  color: white;
}
</style>
