// ONE TRUE SOURCE
const state = {
  toastMessages: [],
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_MESSAGE(state, toastMessage) {
    state.toastMessages = [toastMessage];
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  sendMessage(context, params) {
    context.commit("SET_MESSAGE", params);
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
