<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Alterar Senha</h1>
            <p class="text-white mt-0 mb-5">
              O prazo de 90 dias da sua senha anterior expirou. Cadastre uma
              nova senha para continuar utilizando o sistema.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- PRIFLE FORM -->
      <div class="row">
        <transition name="fade" class="">
          <div
            id="profile-form"
            class="col-12 order-xl-2 order-xs-2 mb-7"
          >
            <card shadow type="secondary">
              <form role="form">
                <div class="row">
                  <div class="col-12">
                    <div class="font-weight-bold mb-3">Senha Atual</div>
                  </div>
                </div>
                <!-- ENTITY REF NAME -->
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i
                        class="text-primary fa fa-lock"
                        title="Senha Atual"
                      ></i
                    ></span>
                  </div>
                  <input
                    type="password"
                    placeholder="Senha Atual"
                    class="form-control"
                    v-model="securityForm.currentPassword"
                    aria-describedby="addon-right addon-left"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="font-weight-bold mb-3">Nova Senha</div>
                  </div>
                </div>
                <!-- ENTITY REF NAME -->
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="text-primary fa fa-lock" title="Senha Nova"></i
                    ></span>
                  </div>
                  <input
                    type="password"
                    placeholder="Nova Senha"
                    class="form-control"
                    :value="get__newPassword"
                    @input="updateNewPassword"
                    aria-describedby="addon-right addon-left"
                  />
                </div>
                <base-alert
                  type="primary"
                  v-if="get__newPasswdScore < 55 && get__newPasswdScore > 1"
                >
                  Para aumentar o nivel de segurança use:
                  <ul>
                    <li>Ao menos um Numero</li>
                    <li>Ao menos uma letra Maiuscula</li>
                    <li>Ao menos uma letra Minuscula</li>
                    <li>Ao menos um caractere especial</li>
                  </ul>
                  <strong>Mínimo: 55/100</strong>
                </base-alert>
                <div
                  class="text-muted font-italic mb-3"
                  v-if="get__newPasswdScore > 1"
                >
                  <small
                    >Nivel de segurança da senha
                    <span>{{ get__newPasswdScore }} / 100</span>
                    <br />
                    <span
                      v-if="get__newPasswdScore > 80"
                      class="text-success font-weight-700"
                      >Forte</span
                    >
                    <span
                      v-else-if="get__newPasswdScore >= 55"
                      class="text-info font-weight-700"
                      >Bom</span
                    >
                    <span
                      v-else-if="get__newPasswdScore >= 30"
                      class="text-warning font-weight-700"
                      >Fraco</span
                    >
                    <span
                      v-else-if="get__newPasswdScore > 5"
                      class="text-danger font-weight-700"
                      >Não recomendado</span
                    >
                    <br />
                  </small>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="font-weight-bold mb-3">Repetir Nova Senha</div>
                  </div>
                </div>
                <!-- ENTITY REF NAME -->
                <div class="form-group input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i
                        class="text-primary fa fa-lock"
                        title="Repetir Senha"
                      ></i
                    ></span>
                  </div>
                  <input
                    type="password"
                    placeholder="Repetir Senha"
                    class="form-control"
                    v-model="securityForm.newConfirmation"
                    aria-describedby="addon-right addon-left"
                  />
                </div>
              </form>
              <template v-slot:footer>
                <button
                  v-if="loadingStatus == 'notLoading'"
                  :disabled="get__newPasswdScore < 55"
                  class="btn btn-success"
                  @click="
                    updatePassword(securityForm)"
                >
                  Atualizar
                </button>
                <button v-else class="btn btn-success" disabled>
                  <i class="el-icon-loading"></i>
                </button>
              </template>
              <!-- colocar aqui -->
            </card>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <!-- END MODAL ADD OFFICE  -->
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      api_url: process.env.VUE_APP_API_ENDPOINT,
      securityForm: {
        currentPassword: "",
        newConfirmation: "",
      },
    };
  },
  methods: {
    ...mapActions("user", {
      updatePassword: "updatePassword",
      updateNewPassword: "updateNewPassword",
    }),
  },
  computed: {
    ...mapState("user", {
      loadingStatus: "loadingStatus",
    }),
    ...mapGetters("user", {
      get__newPassword: "get__newPassword",
      get__newPasswdScore: "get__newPasswdScore",
    }),
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#profile-avatar {
  cursor: pointer !important;
  border-width: 12px;
  border-style: solid;
  font-size: 45px;
}
#group-avatar-user {
  cursor: pointer !important;
  border-width: 12px;
  border-style: solid;
  font-size: 25px;
  padding: 45px;
  z-index: 0;
  position: absolute;
  margin: -140px 0px 0px 70px;
  font-weight: 400;
}
</style>
