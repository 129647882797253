<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(../img/theme/indicadores.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-warning opacity-7"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Relatórios Disponiveis</h1>
            <p class="text-white mt-0 mb-5">
              Análises de dados Privdados e Públicos.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Meus Relatórios</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      href="#"
                      @click.prevent="showFrame = !showFrame"
                      class="btn btn-sm btn-primary"
                      >Trocar relatório</a
                    >
                    <el-tooltip
                      effect="dark"
                      placement="bottom-start"
                      style="cursor: help"
                    >
                      <template #content>
                        Em caso de problemas ao se autenticar no PowerBI, use
                        este botão para sair de sua conta atual e poder se
                        autenticar novamente.
                      </template>
                      <a
                        href="https://app.powerbi.com/Signout"
                        class="btn btn-sm btn-primary"
                        title=""
                        >Logout BI</a
                      >
                    </el-tooltip>

                    <a
                      href="#"
                      @click.prevent="toggleFullscreen"
                      class="btn btn-sm btn-primary"
                      ><i class="fa fa-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <!-- END -->
            <div class="container-fluid p-0">
              <div v-if="!showFrame" class="col-12">
                <div class="row" v-if="reports.length > 0">
                  <div
                    class="col-12 col-xs-12 col-sm-12 col-lg-6 col-xl-4"
                    v-for="(item, index) in reports"
                    :key="index"
                  >
                    <div class="profile">
                      <figure>
                        <img
                          :src="item.image"
                          :alt="item.name"
                          :class="item.status != 'active' ? 'gray-img' : ''"
                        />
                      </figure>
                      <div class="data">
                        <h3 class="no-wrap">
                          <i class="fas fa-chart-pie status-icon"></i>
                          {{ item.name }}
                        </h3>
                        <ul>
                          <li>
                            <strong>Descrição:</strong> {{ item.description }}
                          </li>
                        </ul>
                        <button
                          class="btn btn-success btn-block btn-module mb-2"
                          @click="setIframe(item.iframe_url)"
                        >
                          Acessar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-skeleton style="width: 240px" animated>
                    <template #template>
                      <el-skeleton-item
                        variant="image"
                        style="width: 240px; height: 240px"
                      />
                      <div style="padding: 14px">
                        <el-skeleton-item variant="p" style="width: 50%" />
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-items: space-between;
                          "
                        >
                          <el-skeleton-item
                            variant="text"
                            style="margin-right: 16px"
                          />
                          <el-skeleton-item variant="text" style="width: 30%" />
                        </div>
                      </div>
                    </template>
                  </el-skeleton>
                </div>
              </div>
              <div v-else class="col-12 p-0">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    class="embed-responsive-item"
                    :class="{ 'full-screen': fullScreen }"
                    :src="url"
                    frameborder="0"
                    allowFullScreen="true"
                  ></iframe>
                </div>
              </div>
            </div>
            <!-- END -->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";

export default {
  name: "Relatorios",
  data() {
    return {
      showFrame: false,
      fullScreen: false,
      url: "",
    };
  },
  methods: {
    removePBICookie() {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name +
          "=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
          "; domain=app.powerbi.com";
      }
    },
    setIframe(url) {
      this.showFrame = !this.showFrame;
      this.url = url;
    },
    ...mapActions("indicaBen", {
      fetchReports: "fetchReports",
      toggleFullscreen: "toggleFullscreen",
    }),
  },
  computed: {
    ...mapState("indicaBen", {
      reports: (state) => state.reports,
    }),
  },
  mounted() {
    this.fetchReports();
  },
};
</script>
<style>
strong {
  font-weight: 700;
  color: #fff;
}
.full-screen {
  display: block;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-wrap {
  white-space: nowrap;
}

.btn-module {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgb(23, 43, 77);
  border: none;
}

.profile {
  height: 315px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}
.profile .status-icon {
  display: block;
  font-size: 22px;
  border-radius: 100%;
  background: rgba(23, 43, 77, 0.5);
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
}
.profile figure {
  width: 100%;
  margin: 0;
}
.profile figure img {
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.gray-img {
  filter: grayscale(100%);
}
.profile .data {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 65%;
  transition: 0.3s ease-out;
  background: rgba(23, 43, 77, 0);
  background: -moz-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(23, 43, 77, 0)),
    color-stop(35%, rgba(23, 43, 77, 0.82)),
    color-stop(76%, rgba(23, 43, 77, 1)),
    color-stop(100%, rgba(23, 43, 77, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(23, 43, 77, 0) 0%,
    rgba(23, 43, 77, 0.82) 35%,
    rgba(23, 43, 77, 1) 76%,
    rgba(23, 43, 77, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6B7AE1', endColorstr='#7F60D8', GradientType=0 );
}
.profile:hover .data {
  top: 3%;
  cursor: pointer;
}
.profile .data h3 {
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  min-height: 100px;
  padding: 0 15px;
}
.profile .data ul {
  padding: 0 15px;
  list-style-type: none;
}
.profile .data ul li {
  font-weight: 300;
  color: #ddd;
  margin: 0 0 6px 0;
  font-size: 12px;
}
</style>
