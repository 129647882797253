<template>
  <div class="row justify-content-center">
    <div class="col-8">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-1">
            <small class="font-weight-bold"
              >Seu cadastro será vinculado ao grupo</small
            >
          </div>
          <div class="btn-wrapper text-center">
            <span
              class="shadow avatar avatar-lg bg-gradient-success font-weight-bold"
            >
              {{ groupAcronym }}
            </span>
            <div class="mt-1">
              <strong class="text-primary font-weight-bold">{{
                sponsorGroup.corp_name
              }}</strong>
            </div>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :checked="isOffice"
                @input="updateOffice"
              />
              <small class="form-check-label text-muted" for="defaultCheck1">
                Represento um escritório ou entidade.
              </small>
            </div>
          </div>
          <form role="form">
            <!-- ENTITY FORM -->
            <div v-if="isOffice">
              <div class="form-group mt-4">
                <el-collapse style="border: none !important">
                  <el-collapse-item title="Dados Principais" name="1">
                    <div class="row mt-4">
                      <div class="col-6">
                        <div :class="formValidation.corp_name">
                          <input
                            type="text"
                            v-model="entityData.razao_social"
                            placeholder="Razão Social"
                            aria-describedby="addon-right"
                            class="form-control"
                            @input="updateCorpName"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.nome_fantasia"
                          placeholder="Nome Fantasia"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <div
                          :class="formValidation.corp_id"
                          v-if="entityData.tipo_fornecedor"
                        >
                          <input
                            v-maska="'##.###.###/####-##'"
                            type="text"
                            v-model="entityData.cnpj"
                            placeholder="CNPJ"
                            class="form-control"
                            aria-describedby="addon-right"
                            @input="updateCorpId"
                            v-if="
                              entityData.tipo_fornecedor == 'Pessoa Jurídica'
                            "
                          />
                          <input
                            v-maska="'###.###.###-##'"
                            type="text"
                            v-model="entityData.cnpj"
                            placeholder="CPF"
                            class="form-control"
                            aria-describedby="addon-right"
                            @input="updateCorpId"
                            v-if="entityData.tipo_fornecedor == 'Pessoa Física'"
                          />
                        </div>
                      </div>
                      <div
                        class="col-6"
                        v-if="entityData.tipo_fornecedor == 'Pessoa Jurídica'"
                      >
                        <input
                          type="text"
                          v-model="entityData.cnae"
                          placeholder="CNAE"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="Dados Complementares" name="2">
                    <div class="row mt-4">
                      <div class="col-6">
                        <el-select
                          v-model="entityData.tipo_fornecedor"
                          style="width: 100%"
                          placeholder="Tipo de Fornecedor"
                          size="large"
                          @change="entityData.cnpj = ''"
                        >
                          <el-option
                            v-for="item in opt_fornecedor"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </div>
                      <div class="col-6">
                        <el-select
                          v-model="entityData.tipo_tributacao"
                          style="width: 100%"
                          placeholder="Tipo de Tributação"
                          size="large"
                        >
                          <el-option
                            v-for="item in opt_tributacao"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <el-select
                          v-model="entityData.servico_prestado"
                          style="width: 100%"
                          placeholder="Serviço Prestado"
                          size="large"
                        >
                          <el-option
                            v-for="item in opt_servico"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.codigo_servico"
                          placeholder="Código do Serviço"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div
                      class="row mt-4"
                      v-if="entityData.tipo_fornecedor == 'Pessoa Jurídica'"
                    >
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.inscricao_estadual"
                          placeholder="Inscrição Estadual"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.inscricao_municipal"
                          placeholder="Inscrição Municipal"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="Informações de Contato" name="3">
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          v-maska="'+## (##) #####-####'"
                          type="text"
                          v-model="entityData.telefone1"
                          placeholder="Telefone 1"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          v-maska="'+## (##) #####-####'"
                          type="text"
                          v-model="entityData.telefone2"
                          placeholder="Telefone 2"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.email1"
                          placeholder="E-mail 1"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.email2"
                          placeholder="E-mail 2"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.nome_contato1"
                          placeholder="Nome para Contato 1"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.nome_contato2"
                          placeholder="Nome para Contato 2"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.endereco"
                          placeholder="Endereço"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.complemento"
                          placeholder="Complemento"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.bairro"
                          placeholder="Bairro"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.cidade"
                          placeholder="Cidade"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.estado"
                          placeholder="Estado"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.pais"
                          placeholder="País"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          v-maska="'#####-###'"
                          type="text"
                          v-model="entityData.cep"
                          placeholder="CEP"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6"></div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="Dados Bancários" name="4">
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.nome_banco"
                          placeholder="Nome do Banco"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.agencia"
                          placeholder="Nº da Agência"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.titularidade"
                          placeholder="Titularidade"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.numero_banco"
                          placeholder="Nº do Banco"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-6">
                        <input
                          type="text"
                          v-model="entityData.conta_corrente"
                          placeholder="Conta Corrente"
                          class="form-control"
                        />
                      </div>
                      <div class="col-6"></div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <hr />
            </div>

            <!-- FNAME -->
            <div
              :class="formValidation.fname"
              class="form-group input-group input-group-alternative"
            >
              <div class="input-group-prepend">
                <span class="input-group-text" title="Primeiro Nome"
                  ><i class="text-primary fa fa-user"></i
                ></span>
              </div>
              <input
                v-maska="{
                  mask: 'Z*',
                  tokens: { Z: { pattern: /^[a-zA-Z]*$/ } },
                }"
                type="text"
                placeholder="Nome"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :value="fname"
                @input="updateFname"
              />
            </div>
            <!-- LNAME -->
            <div
              :class="formValidation.lname"
              class="form-group input-group input-group-alternative"
            >
              <div class="input-group-prepend">
                <span class="input-group-text" title="Sobrenome"
                  ><i class="text-primary fa fa-id-card"></i
                ></span>
              </div>
              <input
                v-maska="{
                  mask: 'Z*XZ*XZ*XZ*',
                  tokens: {
                    Z: { pattern: /^[a-zA-Z]*$/ },
                    X: { pattern: /^[' ']*$/ },
                  },
                }"
                type="text"
                placeholder="Sobrenome"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :value="lname"
                @input="updateLname"
              />
            </div>
            <!-- PHONE -->
            <div
              :class="formValidation.phone"
              class="form-group input-group input-group-alternative"
            >
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="text-primary fa fa-phone"></i
                ></span>
              </div>
              <input
                v-maska="['(##) ####-####', '(##) #####-####']"
                type="text"
                placeholder="Celular"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :value="phone"
                @input="updatePhone"
              />
            </div>
            <!-- EMAIL -->
            <div
              :class="formValidation.email"
              class="form-group input-group input-group-alternative"
            >
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="text-primary fa fa-at"></i
                ></span>
              </div>
              <input
                v-maska="{
                  mask: 'Z*',
                  tokens: { Z: { pattern: /^[a-zA-Z0-9@_.&-]*$/ } },
                }"
                type="text"
                placeholder="Email"
                class="form-control disabled"
                disabled
                aria-describedby="addon-right addon-left"
                :value="email"
                @input="updateEmail"
              />
            </div>
            <!-- PASSWORD -->

            <div
              :class="formValidation.password"
              class="form-group input-group input-group-alternative"
            >
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="text-primary fa fa-lock"></i
                ></span>
              </div>

              <input
                type="password"
                placeholder="Insira uma senha"
                class="form-control"
                aria-describedby="addon-right addon-left"
                :value="password"
                @input="updatePassword"
              />
            </div>
            <base-alert
              type="primary"
              v-if="passwdScore < 55 && passwdScore > 1"
            >
              Para aumentar o nivel de segurança use:
              <ul>
                <li>Ao menos um Numero</li>
                <li>Ao menos uma letra Maiuscula</li>
                <li>Ao menos uma letra Minuscula</li>
                <li>Ao menos um caractere especial</li>
              </ul>
              <strong>Mínimo: 55/100</strong>
            </base-alert>
            <div class="text-muted font-italic mb-3" v-if="passwdScore > 1">
              <small
                >Nivel de segurança da senha
                <span>{{ passwdScore }} / 100</span>
                <br />
                <span
                  v-if="passwdScore > 80"
                  class="text-success font-weight-700"
                  >Forte</span
                >
                <span
                  v-else-if="passwdScore >= 55"
                  class="text-info font-weight-700"
                  >Bom</span
                >
                <span
                  v-else-if="passwdScore >= 30"
                  class="text-warning font-weight-700"
                  >Fraco</span
                >
                <span
                  v-else-if="passwdScore > 5"
                  class="text-danger font-weight-700"
                  >Não recomendado</span
                >
                <br />
              </small>
            </div>

            <!--USE TERMS-->
            <Terms
              text-color="dark"
              @input="updateTermsUse"
              @terms="entityData.terms_use = $event"
            />
            <!--USE TERMS-->

            <div class="text-center">
              <button
                v-if="loadingStatus == 'loading'"
                disabled
                class="btn btn-primary btn-block"
              >
                <i class="el-icon-loading"></i>
              </button>
              <button
                v-else
                :disabled="!formValidation.total"
                class="btn btn-primary btn-block"
                title="Verifique se todos os campos foram preenchidos."
                @click.prevent="sendForm(entityData)"
              >
                Finalizar cadastro
              </button>
              <div v-if="!formValidation.total">
                <small>Verifique se todos os campos foram preenchidos.</small>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light">
            <small>Esqueceu a senha?</small>
          </a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Já possuo uma conta</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Terms from "../../components/Terms.vue";

export default {
  components: {
    Terms,
  },
  name: "register",
  data() {
    return {
      termsUse: false,
      entityData: {},
      opt_fornecedor: ["Pessoa Física", "Pessoa Jurídica"],
      opt_tributacao: ["Lucro Simples", "Lucro Presumido", "Lucro Real", "MEI"],
      opt_servico: [
        "Embalagens de Produto",
        "Insumos",
        "Matéria Prima",
        "Material Aera Complementar",
        "Material Caldeiraria",
        "Material Elétrica",
        "Material Escritório",
        "Material informática",
        "Material Instrumentação",
        "Material Laboratório",
        "Material Mecânica",
      ],
    };
  },
  methods: {
    ...mapActions("register", {
      updateFname: "updateFname",
      updateLname: "updateLname",
      updatePhone: "updatePhone",
      updateEmail: "updateEmail",
      updateTermsUse: "updateTermsUse",
      updatePassword: "updatePassword",
      updateCorpId: "updateCorpId",
      updateCorpName: "updateCorpName",
      updateOffice: "updateOffice",
      inputSponsorToken: "inputSponsorToken",
      fetchSponsorGroup: "fetchSponsorGroup",
      sendForm: "sendForm",
    }),
  },
  computed: {
    ...mapState("register", {
      fname: (state) => state.user.fname,
      lname: (state) => state.user.lname,
      phone: (state) => state.user.phone,
      email: (state) => state.user.email,
      terms_use: (state) => state.user.terms_use,
      password: (state) => state.user.password,
      sponsorGroup: (state) => state.sponsorGroup,
      isOffice: (state) => state.isOffice,
      corp: (state) => state.corp,
      loadingStatus: (state) => state.loadingStatus,
    }),
    ...mapGetters("register", {
      passwdScore: "passwdScore",
      formValidation: "formValidation",
      groupAcronym: "getGroupAcronym",
      getEntityData: "getEntityData",
    }),
  },
  async mounted() {
    await this.inputSponsorToken(this.$route.params.token);
    this.entityData = this.getEntityData;
    this.updateCorpId({ target: { value: this.entityData.cnpj || "" } });
    this.updateCorpName({
      target: { value: this.entityData.razao_social || "" },
    });
  },
};
</script>
<style>
.el-collapse-item__header {
  color: #999999 !important;
  font-weight: bold !important;
}
</style>
